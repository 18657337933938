import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './main/header/header.component';
import { GlobalEventsManager } from './utils/providers/global-events-manager';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './utils/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './utils/interceptors/error.interceptor';
import { FooterComponent } from './main/footer/footer.component';
import { MenuSidebarComponent } from './main/menu-sidebar/menu-sidebar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdministratorModule } from './administrator/administrator.module';
import { ToastrModule } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { ContentHeaderComponent } from './main/content-header/content-header.component';
import { OfficeModule } from './office/office.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxColorsModule } from 'ngx-colors';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { LoginComponent } from './authentication/login/login.component';
import { ModalAgendaDatesComponent } from './modals/modal-agenda-dates/modal-agenda-dates.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalAgendaSlotAddComponent } from './modals/modal-agenda-slot-add/modal-agenda-slot-add.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { ModalAgendaExceptionSlotsComponent } from './modals/modal-agenda-exception-slots/modal-agenda-exception-slots.component';
import { ModalAgendaDateAddComponent } from './modals/modal-agenda-date-add/modal-agenda-date-add.component';
import { SignupComponent } from './authentication/signup/signup.component';
import { NgSelectModule } from '@ng-select/ng-select';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    LoginComponent,
    FooterComponent,
    MenuSidebarComponent,
    ContentHeaderComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ModalAgendaDatesComponent,
    ModalAgendaSlotAddComponent,
    ModalAgendaExceptionSlotsComponent,
    ModalAgendaDateAddComponent,
    SignupComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AdministratorModule,
    OfficeModule,
    DataTablesModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true
    }),
    SweetAlert2Module.forRoot(),
    UiSwitchModule.forRoot({
      size: 'large',
      checkedTextColor: '#FFFFFF',
      checkedLabel: 'Attivo',
      uncheckedLabel: 'Non attivo'
    }),
    NgxColorsModule,
    NgbModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule
  ],
  providers: [
    GlobalEventsManager,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
    { provide: LOCALE_ID, useValue: 'it' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
