export class Agenda {
    booking_id: number;
    slot_id: number;
    weekday: number;
    start_time: string;
    end_time: string;
    user_id: number;
    code: string;
    phone: string;
    email: string;
    fullname: string;
    date: number;
    notes: string;
}

export class AgendaWeekdaySlots {
    weekday: number;
    slots: AgendaSlot[] = [];
}

export class AgendaSlot {
    slot_id: number;
    office_id: number;
    start_time: string;
    end_time: string;
}

export class AgendaException {
    date: string;
    slots: AgendaSlot[] = [];
}