<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Registrazione</th>
                                    <th>Nome</th>
                                    <th>Indirizzo</th>
                                    <th>Email</th>
                                    <th>Piano</th>
                                    <th>Stato</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of offices">
                                    <td>{{item.office_id}}</td>
                                    <td>{{item.creation_date * 1000 | date: 'dd/MM/yy HH:mm'}}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.address}}</td>
                                    <td>{{item.email}}</td>
                                    <td [ngSwitch]="item.premium">
                                        <span *ngSwitchCase="0" class="badge badge-pill badge-danger text-uppercase">Base</span>
                                        <span *ngSwitchCase="1" class="badge badge-pill badge-success text-uppercase">Premium</span>
                                    </td>
                                    <td [ngSwitch]="item.enabled">
                                        <span *ngSwitchCase="0" class="badge badge-pill badge-danger text-uppercase">Non
                                            abilitato</span>
                                        <span *ngSwitchCase="1" class="badge badge-pill badge-success text-uppercase">Abilitato</span>
                                    </td>

                                    <td class="text-right">
                                        <button class="btn btn-warning btn-sm" (click)="edit(item)"><i class="fas fa-eye mr-1"></i> Visualizza</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo ufficio?" icon="warning" input="password" inputPlaceholder="Inserisci la password di sicurezza"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" [inputValidator]="checkPassword"
                                            (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>