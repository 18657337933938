import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  jwt_token: string;
  form: FormGroup;
  submitted = false;
  isLoading = false;
  message_error: string;
  message_success: string;
  isDisabled = false;

  constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService, private router: Router, private renderer: Renderer2) {
    this.route.queryParams.subscribe(params => {
      this.jwt_token = params['token'];
    });
  }

  ngOnInit() {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(45)])
    });
  }

  get f() {
    return this.form.controls;
  }

  changePassword() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.isDisabled = true;

    this.authenticationService.changePassword(this.f.password.value, this.jwt_token)
      .pipe(first())
      .subscribe(
        data => {
          this.isDisabled = true;
          this.isLoading = false;
          this.message_success = "Password modificata con successo!";
          this.router.navigate([`/login`]);
        },
        error => {
          this.isLoading = false;
          this.message_error = "Si è verificato un errore!"
        });
  }
}
