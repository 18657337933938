import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // if (err.status === 401 && !err.url.includes("auth/signin")) {
            if (err.status === 401 && err.error == 'Unauthorized' /*!('error' in err) && !err.url.includes("auth/signin")*/) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }           

            const error = err.error.code || err.status || err.statusText;
            
            return throwError(error);
        }))
    }
}