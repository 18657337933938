<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-6">

                <div class="timeline">
                    <ng-container *ngFor="let item of news">
                        <div class="time-label">
                            <span class="bg-red">{{item.creation_date * 1000 | date :'dd MMM yyyy'}}</span>
                        </div>
                        <div>
                            <i class="fas fa-bullhorn bg-info"></i>

                            <div class="timeline-item">
                                <span class="time"><i class="far fa-clock"></i>
                                    {{item.creation_date * 1000 | date : 'HH:mm'}}</span>

                                <h3 class="timeline-header"><strong>{{item.title}}</strong></h3>

                                <img class="product-image"
                                    src="{{item?.image_url ? item.image_url :'assets/img/image_placeholder.png'}}"
                                    alt="News Cover">

                                <div class="timeline-body">
                                    {{item.description}}
                                </div>

                                <div *ngIf="item.attachments?.length > 0" class="timeline-footer">
                                    <span *ngFor="let attachment of item.attachments; let i = index;">
                                        <a href="{{attachment.media_url}}" class="btn btn-default btn-sm"><i
                                            class="fas fa-download mr-2"></i>Allegato {{i+1}}</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>