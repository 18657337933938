import { Component, OnInit } from '@angular/core';
import { GlobalEventsManager } from '../utils/providers/global-events-manager';
import { AuthenticationService } from '../utils/services/authentication.service';
import { RestService } from '../utils/services/rest.service';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.css']
})
export class OfficeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
