<div class="wrapper">
    <app-header (toggleMenuSidebar)="toggleMenuSidebar()"></app-header>

    <app-menu-sidebar></app-menu-sidebar>

    <div #contentWrapper class="content-wrapper">
        <app-content-header></app-content-header>

        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
    <aside class="control-sidebar control-sidebar-dark"></aside>
    <div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>
</div>