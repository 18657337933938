import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  error = '';
  loading = false;
  form: FormGroup;
  submitted = false;

  constructor(private authenticationService: AuthenticationService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required]),
    });

  }

  get f() {
    return this.form.controls;
  }

  login() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.forgotPassword(this.f.email.value)
      .pipe(first())
      .subscribe(
        data => {
          this.toastr.success("Email inviata con successo!");
          this.router.navigate([`/login`]);
        },
        error => {
          this.error = 'Email errata,riprova!';
          this.loading = false;
        });
  }
}
