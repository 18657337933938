import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NewsResponse } from 'src/app/models/response/news-response';
import { Base } from 'src/app/models/base';
import { NewsDetailResponse } from 'src/app/models/response/news-detail-response';
import { OfficesResponse } from 'src/app/models/response/offices-response';
import { OfficeDetailResponse } from 'src/app/models/response/office-detail-response';
import { SectionItemsResponse } from 'src/app/models/response/section-items-response';
import { DocumentsCategoriesResponse } from 'src/app/models/response/documents-categories-response';
import { DocumentsResponse } from 'src/app/models/response/documents-response';
import { UsersResponse } from 'src/app/models/response/users-response';
import { ManagersResponse } from 'src/app/models/response/managers-response';
import { ManagerResponse } from 'src/app/models/response/manager-response';
import { UserResponse } from 'src/app/models/response/user-response';
import { MessagesResponse } from 'src/app/models/response/messages-response';
import { MessagesUsersResponse } from 'src/app/models/response/messages-users-response';
import { MessagesPrivateResponse } from 'src/app/models/response/messages-private-response';
import { BulletinsResponse } from 'src/app/models/response/bulletins-response';
import { SettingsDetailResponse } from 'src/app/models/response/settings-detail-response';
import { DocumentsToolsResponse } from 'src/app/models/response/documents-tools-response';
import { AgendaSlotsResponse } from 'src/app/models/response/agenda-slots-response';
import { AgendaBookingsResponse } from 'src/app/models/response/agenda-bookings-response';
import { AgendaExceptionsResponse } from 'src/app/models/response/agenda-exceptions-response';
import { UsersCategoriesResponse } from 'src/app/models/response/users-categories-response';
import { UsersCategoriesDetailResponse } from 'src/app/models/response/users-categories-detail-response';

@Injectable({ providedIn: 'root' })
export class RestService {
  constructor(private http: HttpClient) { }

  //********************** CAMBIO PASSWORD SUPERADMIN */
  changePassword(user_id, password) {
    return this.http.post<Base>(`${environment.APIweb}/admins/password/change`, { user_id, password });
  }

  //********************** SETTING SUPER ADMIN */
  settingsEdit(premium_trial_days) {
    return this.http.post<Base>(`${environment.APIweb}/settings/edit`, { premium_trial_days });
  }

  settingsDetail() {
    return this.http.post<SettingsDetailResponse>(`${environment.APIweb}/settings/detail`, {});
  }

  //********************** NEWS */
  newsList() {
    return this.http.post<NewsResponse>(`${environment.APIweb}/news/list`, {});
  }

  newsAdd(formData) {
    return this.http.post<Base>(`${environment.APIweb}/news/add`, formData);
  }

  newsEdit(formData) {
    return this.http.post<Base>(`${environment.APIweb}/news/edit`, formData);
  }

  newsDelete(news_id) {
    return this.http.post<Base>(`${environment.APIweb}/news/delete`, { news_id });
  }

  newsDetail(news_id) {
    return this.http.post<NewsDetailResponse>(`${environment.APIweb}/news/detail`, { news_id });
  }

  //********************** UFFICI */
  officesList() {
    return this.http.post<OfficesResponse>(`${environment.APIweb}/offices/list`, {});
  }

  officeEdit(formData) {
    return this.http.post<Base>(`${environment.APIweb}/offices/edit`, formData);
  }

  officeDelete(office_id) {
    return this.http.post<Base>(`${environment.APIweb}/offices/delete`, { office_id });
  }

  officeDetail(office_id) {
    return this.http.post<OfficeDetailResponse>(`${environment.APIweb}/offices/detail`, { office_id });
  }

  //********************** SEZIONI */
  sectionItemsList(office_id, section_id) {
    return this.http.post<SectionItemsResponse>(`${environment.APIweb}/contacts/list`, { office_id, section_id });
  }

  sectionItemEdit(item_id, title, value) {
    return this.http.post<Base>(`${environment.APIweb}/contacts/edit`, { item_id, title, value });
  }

  sectionItemDelete(item_id) {
    return this.http.post<Base>(`${environment.APIweb}/contacts/delete`, { item_id });
  }

  sectionItemAdd(office_id, section_id, title, value) {
    return this.http.post<Base>(`${environment.APIweb}/contacts/add`, { office_id, section_id, title, value });
  }

  //********************** DOCUMENTI */
  bulletinsList(office_id) {
    return this.http.post<BulletinsResponse>(`${environment.APIweb}/bulletins/list`, { office_id });
  }

  bulletinEdit(formData) {
    return this.http.post<Base>(`${environment.APIweb}/bulletins/edit`, formData);
  }

  bulletinDelete(bulletin_id) {
    return this.http.post<Base>(`${environment.APIweb}/bulletins/delete`, { bulletin_id });
  }

  bulletinAdd(formData) {
    return this.http.post<Base>(`${environment.APIweb}/bulletins/add`, formData);
  }

  //********************** UTENTI CLIENTI */
  usersList(office_id) {
    return this.http.post<UsersResponse>(`${environment.APIweb}/users/list`, { office_id });
  }

  userDetail(user_id) {
    return this.http.post<UserResponse>(`${environment.APIweb}/users/detail`, { user_id });
  }

  userEdit(formData) {
    return this.http.post<Base>(`${environment.APIweb}/users/edit`, formData);
  }

  userDelete(user_id) {
    return this.http.post<Base>(`${environment.APIweb}/users/delete`, { user_id });
  }

  userAdd(formData) {
    return this.http.post<Base>(`${environment.APIweb}/users/add`, formData);
  }

  userChangePassword(user_id, password, email) {
    return this.http.post<Base>(`${environment.APIweb}/users/password/change`, { user_id, password, email });
  }

  userFavoriteSet(user_id, value) {
    return this.http.post<Base>(`${environment.APIweb}/users/favorites/set`, { user_id, value });
  }

  //********************** UTENTI MANAGER */
  managersList(office_id) {
    return this.http.post<ManagersResponse>(`${environment.APIweb}/managers/list`, { office_id });
  }

  managerDetail(user_id) {
    return this.http.post<ManagerResponse>(`${environment.APIweb}/managers/detail`, { user_id });
  }

  managerEdit(formData) {
    return this.http.post<Base>(`${environment.APIweb}/managers/edit`, formData);
  }

  managerDelete(user_id) {
    return this.http.post<Base>(`${environment.APIweb}/managers/delete`, { user_id });
  }

  managerAdd(formData) {
    return this.http.post<Base>(`${environment.APIweb}/managers/add`, formData);
  }

  managerUsersAdd(user_id, manager_id) {
    return this.http.post<Base>(`${environment.APIweb}/managers/users/add`, { user_id, manager_id });
  }

  managerUsersDelete(user_id, manager_id) {
    return this.http.post<Base>(`${environment.APIweb}/managers/users/delete`, { user_id, manager_id });
  }

  //********************** MESSAGGI PUBBLICI */
  messagesPublicList(office_id) {
    return this.http.post<MessagesResponse>(`${environment.APIweb}/messages/public/list`, { office_id });
  }

  messagePublicSend(formData) {
    return this.http.post<Base>(`${environment.APIweb}/messages/public/send`, formData);
  }

  messagePublicEdit(formData) {
    return this.http.post<Base>(`${environment.APIweb}/messages/public/edit`, formData);
  }

  messagePublicDelete(message_id) {
    return this.http.post<Base>(`${environment.APIweb}/messages/public/delete`, { message_id });
  }

  //********************** MESSAGGI PRIVATI */
  messagesPrivateUsersList(office_id) {
    return this.http.post<MessagesUsersResponse>(`${environment.APIweb}/messages/private/users/list`, { office_id });
  }

  messagePrivateSend(formData) {
    return this.http.post<Base>(`${environment.APIweb}/messages/private/send`, formData);
  }

  messagePrivateDelete(message_id, status) {
    return this.http.post<Base>(`${environment.APIweb}/messages/private/delete`, { message_id, status });
  }

  messagesPrivateList(user_id) {
    return this.http.post<MessagesPrivateResponse>(`${environment.APIweb}/messages/private/list`, { user_id });
  }

  //********************** DOCUMENTI PRIVATI TRA OFFICE E USER */
  categoriesDocumentsPrivateList(user_id) {
    return this.http.post<DocumentsCategoriesResponse>(`${environment.APIweb}/documents/private/categories/list`, { user_id });
  }
  categoriesDocumentsPrivateEdit(category_id, name) {
    return this.http.post<Base>(`${environment.APIweb}/documents/private/categories/edit`, { category_id, name });
  }
  categoriesDocumentsPrivateAdd(user_id, name) {
    return this.http.post<Base>(`${environment.APIweb}/documents/private/categories/add`, { user_id, name });
  }
  categoriesDocumentsPrivateDelete(category_id) {
    return this.http.post<Base>(`${environment.APIweb}/documents/private/categories/delete`, { category_id });
  }

  documentsPrivateList(category_id) {
    return this.http.post<DocumentsResponse>(`${environment.APIweb}/documents/private/list`, { category_id });
  }

  documentPrivateEdit(formData) {
    return this.http.post<Base>(`${environment.APIweb}/documents/private/edit`, formData);
  }

  documentPrivateDelete(document_id) {
    return this.http.post<Base>(`${environment.APIweb}/documents/private/delete`, { document_id });
  }

  documentPrivateAdd(formData) {
    return this.http.post<Base>(`${environment.APIweb}/documents/private/add`, formData);
  }

  //********************** TOOLS */
  toolsList() {
    return this.http.post<DocumentsToolsResponse>(`${environment.APIweb}/tools/list`, {});
  }

  toolEdit(formData) {
    return this.http.post<Base>(`${environment.APIweb}/tools/edit`, formData);
  }

  toolDelete(tool_id) {
    return this.http.post<Base>(`${environment.APIweb}/tools/delete`, { tool_id });
  }

  toolAdd(formData) {
    return this.http.post<Base>(`${environment.APIweb}/tools/add`, formData);
  }

  //********************** AGENDA */
  agendaSlotsList() {
    return this.http.post<AgendaSlotsResponse>(`${environment.APIweb}/agendas/slots/list`, {});
  }
  agendaSlotAdd(weekday, start_time, end_time) {
    return this.http.post<Base>(`${environment.APIweb}/agendas/slots/add`, { weekday, start_time, end_time });
  }
  agendaSlotDelete(slot_id) {
    return this.http.post<Base>(`${environment.APIweb}/agendas/slots/delete`, { slot_id });
  }
  agendaBookingsList() {
    return this.http.post<AgendaBookingsResponse>(`${environment.APIweb}/agendas/bookings/list`, {});
  }
  agendaBookingDelete(booking_id) {
    return this.http.post<Base>(`${environment.APIweb}/agendas/bookings/delete`, { booking_id });
  }
  agendaExceptionsList() {
    return this.http.post<AgendaExceptionsResponse>(`${environment.APIweb}/agendas/exceptions/dates/list`, {});
  }
  agendaExceptionDateAdd(date) {
    return this.http.post<Base>(`${environment.APIweb}/agendas/exceptions/dates/add`, { date });
  }
  agendaExceptionDateDelete(date) {
    return this.http.post<Base>(`${environment.APIweb}/agendas/exceptions/dates/delete`, { date });
  }
  agendaExceptionSlotAdd(date, start_time, end_time) {
    return this.http.post<Base>(`${environment.APIweb}/agendas/exceptions/dates/slots/add`, { date, start_time, end_time });
  }
  agendaExceptionSlotDelete(slot_id) {
    return this.http.post<Base>(`${environment.APIweb}/agendas/exceptions/dates/slots/delete`, { slot_id });
  }
  agendaExceptionDateClose(date) {
    return this.http.post<Base>(`${environment.APIweb}/agendas/exceptions/dates/close`, { date });
  }
  agendaInfoEdit(formData) {
    return this.http.post<Base>(`${environment.APIweb}/offices/agenda/info/edit`, formData);
  }

  //********************** CATEGORIE CLIENTI */

  categoriesUsersList() {
    return this.http.post<UsersCategoriesResponse>(`${environment.APIweb}/users/categories/list`, { });
  }

  categoriesUsersAdd(name) {
    return this.http.post<Base>(`${environment.APIweb}/users/categories/add`, { name });
  }

  categoriesUserDetail(category_id) {
    return this.http.post<UsersCategoriesDetailResponse>(`${environment.APIweb}/users/categories/detail`, { category_id });
  }

  categoriesUserEdit(category_id, name) {
    return this.http.post<Base>(`${environment.APIweb}/users/categories/edit`, { category_id, name });
  }

  categoriesUsersDelete(category_id) {
    return this.http.post<Base>(`${environment.APIweb}/users/categories/delete`, { category_id });
  }
}
