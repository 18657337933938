import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgendaSlot, AgendaWeekdaySlots } from 'src/app/models/agenda';
import { RestService } from 'src/app/utils/services/rest.service';
import * as moment from 'moment';
import { AppConstants } from 'src/app/utils/constants';
import { ModalAgendaSlotAddComponent } from '../modal-agenda-slot-add/modal-agenda-slot-add.component';

@Component({
  selector: 'app-modal-agenda-dates',
  templateUrl: './modal-agenda-dates.component.html',
  styleUrls: ['./modal-agenda-dates.component.css']
})
export class ModalAgendaDatesComponent implements OnInit {

  @Input() item: AgendaWeekdaySlots;

  weekdays = AppConstants.WEEKDAYS;

  constructor(public activeModal: NgbActiveModal, private rest: RestService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  getSlot(item) {
    return moment(item, "HH:mm:ss").format("HH:mm");
  }

  /**
   * Aggiunta di uno slot
   */
  addSlot() {
    const modalRef = this.modalService.open(ModalAgendaSlotAddComponent);
    modalRef.result.then((result: AgendaSlot) => {
      this.rest.agendaSlotAdd(this.item.weekday, result.start_time, result.end_time).subscribe(res => {
        this.item.slots.push(res.data.slot);
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }, (reason) => { });
  }

  /**
   * Eliminazione di uno slot
   */
  deleteSlot(item: AgendaSlot) {    
    this.rest.agendaSlotDelete(item.slot_id).subscribe(res => {
      this.item.slots = this.item.slots.filter(i => i.slot_id != item.slot_id);
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
