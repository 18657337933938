import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.css']
})
export class CategoryDetailComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  role: number;
  office_id: number;
  category_id: number;
  users: User[];

  form: FormGroup;
  submitted = false;
  saving = false;
  isLoading = false;
  isUpdate: boolean;

  constructor(private router: Router, private rest: RestService, private toastr: ToastrService,
    private route: ActivatedRoute, private globalEventsManager: GlobalEventsManager,
    private formBuilder: FormBuilder) {
    this.office_id = this.route.snapshot.params['id'];
    this.category_id = this.route.snapshot.params['category_id'];
    this.role = this.globalEventsManager.getUserRole();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [null, Validators.required]
    });

    if (this.category_id) {
      this.dtOptions = {
        order: [0, "asc"],
        language: {
          url: "./assets/datatable_ITA.json"
        }
      };

      this.categoriesDetail();
    }
  }

  get f() {
    return this.form.controls;
  }

  categoriesDetail() {
    this.isLoading = true;

    this.rest.categoriesUserDetail(this.category_id).subscribe(res => {
      this.isLoading = false;

      this.form.patchValue({
        name: res.data.category.name
      });

      this.users = res.data.users;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    var name = this.f.name.value;
    this.saving = true;

    if (this.category_id) {
      this.rest.categoriesUserEdit(this.category_id, name).subscribe(res => {
        this.saving = false;
        this.toastr.success('Categoria modificata con successo!');
        this.router.navigate([`/office/${this.office_id}/users/categories`]);
      }, error => {
        this.saving = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.categoriesUsersAdd(name).subscribe(res => {
        this.saving = false;
        this.toastr.success('Categoria creata con successo!');
        this.router.navigate([`/office/${this.office_id}/users/categories`]);
      }, error => {
        this.saving = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }

  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
