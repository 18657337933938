<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th style="width: 15%">Data di caricamento</th>
                                    <th>Titolo</th>
                                    <th>Descrizione</th>
                                    <th style="width: 25%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of documents">
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.description}}</td>

                                    <td class="text-right">
                                        <a href="{{item.media_url}}" class="btn btn-default btn-sm"><i class="fas fa-download mr-2"></i>Scarica
                                            Allegato</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingDocuments" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>