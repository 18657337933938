<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <button class="btn btn-info mb-3" (click)="editSectionItem(null)"><i class="fas fa-plus mr-1"></i>
                    Aggiungi Contatto</button>

                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                            class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Titolo</th>
                                    <th>Telefono</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of items">
                                    <td>{{item.title}}</td>
                                    <td>{{item.value}}</td>

                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="editSectionItem(item)"><i
                                                class="fas fa-pen mr-1"></i> Modifica</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo indirizzo email?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deleteSectionItem(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="form">
                <div class="modal-body">
                    <div class="row">
                        <input formControlName="item_id" type="hidden">

                        <div class="form-group col-12">
                            <label>Titolo</label>
                            <input formControlName="title" type="text" class="form-control"
                                placeholder="Inserisci un titolo"
                                [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                            <div *ngIf="f.title.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                        <div class="form-group col-12">
                            <label>Telefono</label>
                            <input formControlName="value" type="tel" pattern="[0-9]+" class="form-control"
                                placeholder="Inserisci un numero di telefono"
                                [ngClass]="{ 'is-invalid': submitted && f.value.errors }" /> 
                            <div *ngIf="f.value.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                            <div *ngIf="f.value.errors?.pattern" class="invalid-feedback">Numero non valido</div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button type="submit" class="btn btn-info" (click)="saveSectionItem()">Salva</button>
                </div>
            </form>

        </div>
    </div>
</div>