import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  isLoading: boolean = false;
  submitted = false;
  saving = false;

  form: FormGroup;

  constructor(private router: Router, private rest: RestService, private toastr: ToastrService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      premium_trial_days: ['', Validators.required]
    });

    this.settingsDetail();
  }

  settingsDetail() {
    this.isLoading = true;

    this.rest.settingsDetail().subscribe(res => {
      this.isLoading = false;

      this.form.setValue({
        premium_trial_days: res.data.settings.premium_trial_days
      })
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }


  get f() {
    return this.form.controls;
  }

  save() {
    var premium_trial_days = this.f.premium_trial_days.value;
    this.saving = true;

    this.rest.settingsEdit(premium_trial_days).subscribe(res => {
      this.saving = false;
      this.toastr.success('Impostazioni modificate con successo!');
    }, error => {
      this.saving = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }
}