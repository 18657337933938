import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, PRIMARY_OUTLET, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';

interface IBreadcrumb {
  label: string;
  params?: Params;
  url: string;
}

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.css']
})
export class ContentHeaderComponent implements OnInit {
  public pageName: String;
  public breadcrumbs: IBreadcrumb[];
  public officeName: String;

  previous_url;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private _globalEventsManager: GlobalEventsManager) {
    this.breadcrumbs = [];

    this._globalEventsManager.getOfficeNameEmitter.subscribe((name) => {      
      this.officeName = name;            
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let root: ActivatedRoute = this.activatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root);

        this.pageName = this.breadcrumbs[this.breadcrumbs.length - 1]?.label;        

        // se sono superadmin non devo mostrare il nome dell'office quando mi trovo nelle sezioni generale del superadmin
        if (event.url.includes('administrator')) {
          this._globalEventsManager.showOfficeNavBar(false);
        }
      }
    });

  }

  ngOnInit(): void {
  }

  // back() {
  //   this.router.navigate(this.previous_url);
  // }

  private getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

    //get the child routes
    let children: ActivatedRoute[] = route.children;

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //get the route's URL segment
      let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");

      //append route URL to URL
      url += `/${routeURL}`;

      //add breadcrumb
      let breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url
      };
      breadcrumbs.push(breadcrumb);

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    //we should never get here, but just in case
    return breadcrumbs;
  }

}
