<aside #mainSidebar class="main-sidebar sidebar-dark-info elevation-4">
  <!-- Brand Logo -->
  <div class="sidebar-light-info text-center">
    <a href="#" class=" brand-link logo-switch">
      <img src="assets/img/ic_logo.png" alt="THnet Logo Small" class="brand-image-xl logo-xs">
      <img src="assets/img/logo.png" alt="THnet Logo Large" class="brand-image-xs logo-xl" style="left: 43px; max-height: 40px;">
    </a>
  </div>

  <div class="sidebar">
    <nav class="mt-2">


      <!-- superadmin -->
      <div *ngIf="!showOfficeNavBar && role === 3">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/administrator/news']" class="nav-link">
              <i class="nav-icon fas fa-newspaper"></i>
              <p>Comunicazioni</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/administrator/offices']" class="nav-link">
              <i class="nav-icon fas fa-briefcase"></i>
              <p>Uffici</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/administrator/tools']" class="nav-link">
              <i class="nav-icon fas fa-book"></i>
              <p>Strumenti</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/administrator/settings']" class="nav-link">
              <i class="nav-icon fas fa-cog"></i>
              <p>Impostazioni</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/administrator/change_password']" class="nav-link">
              <i class="nav-icon fas fa-key"></i>
              <p>Cambio Password</p>
            </a>
          </li>
        </ul>
      </div>

      <!-- studi -->
      <div *ngIf="showOfficeNavBar && office_id">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li *ngIf="role === 2 || role === 3" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'profile']" class="nav-link">
              <i class="nav-icon fas fa-briefcase"></i>
              <p>Profilo</p>
            </a>
          </li>
          <li *ngIf="role === 2 || role === 4" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'news']" class="nav-link">
              <i class="nav-icon fas fa-newspaper"></i>
              <p>Comunicazioni e News</p>
            </a>
          </li>
          <li *ngIf="role === 2" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'email']" class="nav-link">
              <i class="nav-icon fas fa-envelope"></i>
              <p>Email</p>
            </a>
          </li>
          <li *ngIf="role === 2" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'contacts']" class="nav-link">
              <i class="nav-icon fas fa-address-book"></i>
              <p>Numeri Utili</p>
            </a>
          </li>
          <li *ngIf="role === 2" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'links']" class="nav-link">
              <i class="nav-icon fas fa-globe"></i>
              <p>Link Utili</p>
            </a>
          </li>
          <li *ngIf="role === 2" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'services']" class="nav-link">
              <i class="nav-icon fas fa-tasks"></i>
              <p>Servizi</p>
            </a>
          </li>
          <li *ngIf="role === 2 || role === 4" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'users']" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>Clienti</p>
            </a>
          </li>
          <li *ngIf="role === 2" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'bulletins']" class="nav-link">
              <i class="nav-icon fas fa-folder-open"></i>
              <p>Bacheca/Tue News</p>
            </a>
          </li>
          <li *ngIf="role === 2 || role === 4" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'messages', 'public']" class="nav-link">
              <i class="nav-icon fas fa-comment-alt"></i>
              <p>Messaggi Pubblici</p>
            </a>
          </li>
          <li *ngIf="role === 2 || role === 4" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'messages', 'private']" class="nav-link">
              <i class="nav-icon fas fa-comments"></i>
              <p>Messaggi Privati</p>
            </a>
          </li>
          <li *ngIf="role === 2" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'managers']" class="nav-link">
              <i class="nav-icon fas fa-user-tie"></i>
              <p>Gestori</p>
            </a>
          </li>
          <li *ngIf="role === 2" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'agenda']" class="nav-link">
              <i class="nav-icon fas fa-calendar"></i>
              <p>Agenda</p>
            </a>
          </li>
          <li *ngIf="role === 2" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['/office', office_id, 'tools']" class="nav-link">
              <i class="nav-icon fas fa-book"></i>
              <p>Strumenti</p>
            </a>
          </li>

          <li *ngIf="role === 3" class="nav-item">
            <a id="back" class="nav-link active" [routerLink]="['/administrator/offices']" (click)="backHistory()">
              <i class="nav-icon fas fa-arrow-left"></i>
              <p>Menu principale</p>
            </a>
          </li>
        </ul>
      </div>

    </nav>
  </div>
</aside>