export class AppConstants {
    public static USER_TYPE_USER = 1;
    public static USER_TYPE_OFFICE = 2;
    public static USER_TYPE_SUPER_ADMIN = 3;
    public static USER_TYPE_MANAGER = 4;

    public static SECTION_EMAIL = 1;
    public static SECTION_CONTACTS = 2;
    public static SECTION_LINKS = 3;
    public static SECTION_SERVICES = 4;

    public static USER_TYPES = [
        { type_id: AppConstants.USER_TYPE_USER, type_name: "Utente" },
        { type_id: AppConstants.USER_TYPE_OFFICE, type_name: "Studio" },
        { type_id: AppConstants.USER_TYPE_SUPER_ADMIN, type_name: "THnet" },
        { type_id: AppConstants.USER_TYPE_MANAGER, type_name: "Manager" }
    ]

    public static ADMIN_PASSWORD = "$salvatoreth";
    public static PASSWORD_DELETE = "elimina";

    public static WEEKDAYS = ["Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato", "Domenica"];

}