<div class="login-page">
    <div class="login-box">
        <div class="login-logo">
            <img src="assets/img/logo.png" alt="MioStudio Logo Large">
        </div>
        
        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">Hai dimenticato la password? Inserisci il tuo indirizzo email.</p>

                <form [formGroup]="form">
                    <div class="input-group mb-3">
                        <input formControlName="email" type="email" class="form-control" placeholder="Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div class="invalid-feedback order-last">Campo obbligatorio</div>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-12">
                            <button [disabled]="loading" class="btn btn-info btn-block" (click)="login()" type="submit">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Richiedi nuova password
                            </button>
                        </div>
                    </div>
                    <div *ngIf="error" class="text-center alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>

                <p class="mt-3 mb-1">
                    <a [routerLink]="['/login']">Login</a>
                </p>
            </div>
        </div>
    </div>
</div>