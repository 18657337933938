<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button class="btn btn-default btn-sm mb-1" [routerLink]="['/office', office_id, 'users', user_id]"><i class="fas fa-arrow-left mr-2"></i>Torna
                    indietro</button>

                <div class="mb-3">
                    <button class="btn btn-info" (click)="editDocument(null)"><i class="fas fa-plus mr-1"></i>
                        Aggiungi Documento</button>
                </div>

                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th style="width: 15%">Data di caricamento</th>
                                    <th>Titolo</th>
                                    <th>Descrizione</th>
                                    <th>Chi ha caricato</th>
                                    <th style="width: 25%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of documents">
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.description}}</td>
                                    <td>{{item.owner_name}}</td>

                                    <td class="text-right">
                                        <span>
                                            <a href="{{item.media_url}}" class="btn btn-default btn-sm"><i class="fas fa-download mr-2"></i>Scarica
                                                Allegato</a>
                                        </span>
                                        <button class="btn btn-info btn-sm" (click)="editDocument(item)"><i class="fas fa-pen mr-1"></i> Modifica</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo documento?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" (confirm)="deleteDocument(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingDocuments" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="form" id="form">
                <div class="modal-body">
                    <div class="row">
                        <input formControlName="document_id" type="hidden">

                        <div class="form-group col-12">
                            <label>Titolo</label>
                            <input formControlName="title" type="text" class="form-control" placeholder="Inserisci il titolo del documento"
                                [ngClass]="{ 'is-invalid': submittedDocument && f.title.errors }" />
                            <div *ngIf="f.title.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                        <div class="form-group col-12">
                            <label>Descrizione</label>
                            <textarea placeholder="Inserisci una descrizione..." formControlName="description" cols="40" rows="5" class="form-control"></textarea>
                        </div>

                        <div class="form-group col-12">
                            <label>Documento</label>
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="attachment" formControlName="attachment" (change)="handleFile($event.target.files)"
                                    [ngClass]="{ 'is-invalid': submittedDocument && f.attachment.errors }">
                                <label class="custom-file-label" for="attachment">Seleziona un documento</label>
                                <div *ngIf="f.attachment.errors?.required" class="invalid-feedback">Campo obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>

                    <button type="submit" class="btn btn-info" (click)="saveDocument()" [disabled]="sendingDocument">
                        <span *ngIf="sendingDocument" class="spinner-border spinner-border-sm mr-1"></span>
                        Invia</button>

                </div>
            </form>

        </div>
    </div>
</div>