import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { News } from 'src/app/models/news';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  news: News[];

  constructor(
    private router: Router,
    private rest: RestService,
    private toastr: ToastrService,
    public globalManager: GlobalEventsManager
  ) { }

  ngOnInit(): void {
    this.newsList();
  }

  /**
  * Lista
  */
  newsList() {
    this.rest.newsList().subscribe(res => {
      this.news = res.data.news;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }
}
