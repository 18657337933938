import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import bsCustomFileInput from 'bs-custom-file-input';
import { Document } from 'src/app/models/document';
import { Bulletin } from 'src/app/models/bulletin';

declare var $: any;

@Component({
  selector: 'app-bulletins',
  templateUrl: './bulletins.component.html',
  styleUrls: ['./bulletins.component.css']
})
export class BulletinsComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  office_id: number;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  form: FormGroup;
  isLoading: boolean;
  bulletins: Bulletin[];
  isUpdate: boolean;
  submitted = false;
  fileAttachment: File;
  sendingDocument = false;

  constructor(public globalManager: GlobalEventsManager, private formBuilder: FormBuilder,
    private route: ActivatedRoute, private rest: RestService, private toastr: ToastrService, private router: Router) {
    this.office_id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.dtOptions = {
      order: [[0, 'desc']],
      columnDefs: [
        { targets: 0, type: 'date-euro' },
        { orderable: false, targets: [3] }],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = this.formBuilder.group({
      bulletin_id: [null],
      title: ['', Validators.required],
      description: [''],
      attachment: [null]
    });

    this.bulletinsList();
  }

  get f() {
    return this.form.controls;
  }

  ngAfterViewInit() {
    bsCustomFileInput.init();
  }

  /**
  * Lista 
  */
  bulletinsList() {
    this.isLoading = true;

    this.rest.bulletinsList(this.office_id).subscribe(res => {
      this.isLoading = false;

      this.bulletins = res.data.bulletins;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  handleFile(files: FileList) {
    if (files && files.length > 0) {
      this.fileAttachment = files[0];
    }
  }

  edit(item: Bulletin) {
    this.submitted = false;

    $('#form')[0].reset();
    this.fileAttachment = null;

    if (item) {
      this.form.patchValue({
        bulletin_id: item.bulletin_id,
        title: item.title,
        description: item.description
      });
    }

    $("#modal").modal('show');
  }

  delete(item: Bulletin) {
    this.rest.bulletinDelete(item.bulletin_id).subscribe(res => {
      this.toastr.success('News eliminata con successo!');
      this.bulletinsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  save() {
    var bulletinID = this.f.bulletin_id.value;

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.sendingDocument = true;

    const formData = new FormData();
    formData.append("office_id", this.office_id.toString());
    formData.append("title", this.f.title.value);

    if (this.f.description.value) formData.append("description", this.f.description.value);
    if (this.fileAttachment) formData.append("file", this.fileAttachment);

    if (bulletinID) {
      formData.append("bulletin_id", bulletinID);

      this.rest.bulletinEdit(formData).subscribe(res => {
        this.sendingDocument = false;
        $("#modal").modal('hide');
        this.toastr.success('News modificata con successo!');
        this.bulletinsList();
      }, error => {
        this.sendingDocument = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.bulletinAdd(formData).subscribe(res => {
        this.sendingDocument = false;
        $("#modal").modal('hide');
        this.toastr.success('News aggiunta con successo!');
        this.bulletinsList();
      }, error => {
        this.sendingDocument = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    bsCustomFileInput.destroy();
  }
}

