import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { SectionItem } from 'src/app/models/section-item';
import { AppConstants } from 'src/app/utils/constants';
declare var $: any;

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  office_id: number;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  form: FormGroup;
  isLoading: boolean;
  items: SectionItem[];
  isUpdate: boolean;
  submitted = false;

  constructor(public globalManager: GlobalEventsManager, private formBuilder: FormBuilder,
    private route: ActivatedRoute, private rest: RestService, private toastr: ToastrService, private router: Router) {
    this.office_id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [2] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = this.formBuilder.group({
      item_id: [null],
      title: ['', Validators.required],
      value: ['', Validators.required]
    });

    this.getList();
  }

  get f() {
    return this.form.controls;
  }

  /**
  * Lista dei numeri utili
  */
  getList() {
    this.isLoading = true;

    this.rest.sectionItemsList(this.office_id, AppConstants.SECTION_EMAIL).subscribe(res => {
      this.isLoading = false;

      this.items = res.data.contacts;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  editSectionItem(item: SectionItem) {
    this.submitted = false;

    if (item) {
      this.form.patchValue({
        item_id: item.item_id,
        title: item.title,
        value: item.value
      });
    } else {
      this.form.reset();
    }
    $("#modal").modal('show');
  }

  deleteSectionItem(item: SectionItem) {
    this.rest.sectionItemDelete(item.item_id).subscribe(res => {
      this.toastr.success('Indirizzo email eliminato con successo!');
      this.getList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  saveSectionItem() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    var itemID = this.f.item_id.value;
    var title = this.f.title.value;
    var value = this.f.value.value.toString();

    if (itemID) {
      this.rest.sectionItemEdit(itemID, title, value).subscribe(res => {
        $("#modal").modal('hide');
        this.toastr.success('Indirizzo email modificato con successo!');
        this.getList();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.sectionItemAdd(this.office_id, AppConstants.SECTION_EMAIL, title, value).subscribe(res => {
        $("#modal").modal('hide');
        this.toastr.success('Indirizzo email aggiunto con successo!');
        this.getList();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
