<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <form [formGroup]="form">
                    <div class="card card-info card-outline">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-3 mx-auto">
                                    <label>Periodo di prova PREMIUM (giorni)</label>
                                    <input type="number" class="form-control" formControlName="premium_trial_days" [ngClass]="{ 'is-invalid': submitted && f.premium_trial_days.errors }">
                                    <div *ngIf="f.premium_trial_days.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()" [disabled]="saving"><span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>