import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { News } from 'src/app/models/news';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/constants';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  news: News[];
  isUpdate: boolean;

  constructor(private router: Router, private rest: RestService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [4] }],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.newsList();
  }

  /**
    * Lista
    */
  newsList() {
    this.isLoading = true;

    this.rest.newsList().subscribe(res => {
      this.isLoading = false;

      this.news = res.data.news;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Lista degli utenti che possono visualizzare la news
   * @param usersType 
   */
  getUsersVisibility(usersType) {
    return AppConstants.USER_TYPES.filter(function (u) {
      return u.type_id != AppConstants.USER_TYPE_SUPER_ADMIN && usersType.includes(u.type_id);
    }).map(function (u) {
      return u.type_name;
    })
  }

  /**
   * Dettaglio
   */
  edit(item: News) {
    this.router.navigate([`/administrator/news/${item.news_id}`]);
  }

  /**
   * Elimina
   */
  checkPassword(value) {
    if (value != AppConstants.ADMIN_PASSWORD) return "Password errata";
  }
  delete(item: News) {
    this.rest.newsDelete(item.news_id).subscribe(res => {
      this.toastr.success('Comunicazione eliminata con successo!');
      this.newsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
