import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { Location } from '@angular/common';
import { Message } from 'src/app/models/message';
import { User } from 'src/app/models/user';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-messages-private-detail',
  templateUrl: './messages-private-detail.component.html',
  styleUrls: ['./messages-private-detail.component.css']
})
export class MessagesPrivateDetailComponent implements OnInit {
  @ViewChild('attachment') attachment: any;

  @ViewChildren('messages') messagesElem: QueryList<any>;
  @ViewChild('content') contentElem: ElementRef;

  isLoading: boolean;
  submitted = false;
  saving = false;
  form: FormGroup;

  office_id: number;
  user_id: number;

  messages: Message[];
  user: User;
  managers: User[];

  filesAttachment: File[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private rest: RestService, private toastr: ToastrService,
    private formBuilder: FormBuilder, private utils: GlobalEventsManager, private location: Location) {

    this.office_id = this.route.snapshot.params['id'];
    this.user_id = this.route.snapshot.params['user_id'];
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      message: ['', Validators.required]
    });

    this.messagesList();
  }

  get f() {
    return this.form.controls;
  }

  ngAfterViewInit() {
    this.scrollToBottom();
    this.messagesElem.changes.subscribe(this.scrollToBottom);
  }

  scrollToBottom = () => {
    try {
      // var offTop = this.contentElem.nativeElement.scrollHeight;
      this.contentElem.nativeElement.scrollTop = this.contentElem.nativeElement.scrollHeight;
      // $("#content-messages").animate({ scrollTop: offTop });
    } catch (err) { }
  }

  /**
    * Lista
    */
  messagesList() {
    this.isLoading = true;

    this.rest.messagesPrivateList(this.user_id).subscribe(res => {

      this.messages = res.data.messages;
      this.user = res.data.user;      
      this.managers = res.data.managers;

      this.isLoading = false;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  isMyMessage(item: Message) {
    return item.sender_id != this.user_id;
  }

  /**
   * Listener immagine cover
   * @param files 
   */
  handleFileAttachment(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.filesAttachment.push(files[i]);
    }
  }

  /**
 * Rimozione file allegato
 */
  removeAttachment(position) {
    this.attachment.nativeElement.value = '';
    this.filesAttachment.splice(position, 1);
  }

  sendMessage() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    const formData = new FormData();

    formData.append("office_id", this.office_id.toString());
    formData.append("user_id", this.user_id.toString());
    formData.append("message", this.f.message.value);

    if (this.filesAttachment) {
      formData.append("type", "1");

      for (let i = 0; i < this.filesAttachment.length; i++) {
        formData.append('file', this.filesAttachment[i]);
      }
    } else {
      formData.append("type", "0");
    }

    this.send(formData);
  }

  send(formData) {
    this.saving = true;

    this.rest.messagePrivateSend(formData).subscribe(res => {
      this.submitted = false;
      this.saving = false;
      this.toastr.success('Messaggio inviato!');
      this.form.reset();
      this.messagesList();

      this.attachment.nativeElement.value = '';
      this.filesAttachment = [];
    }, error => {
      this.submitted = false;
      this.saving = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Stampa messaggio
   */
  printMessage(item: Message, fullname) {
    var message = moment(item.creation_date * 1000).format('DD/MM/YYYY - HH:mm');
    if (fullname) {
      message += '<br>';
      message += '<strong>' + fullname + '</strong>';
    }
    message += '<br>';
    message += item.message;
    this.utils.printHtml(message);
  }

  /**
 * Stampa messaggio
 */
  printMessages() {
    var html = "<strong>Storico messaggi</strong>:";
    html += '<br>';
    html += 'Codice Cliente: ' + this.user.code;
    html += '<br>';
    html += '<br>';

    html += document.getElementById("content-messages").innerHTML;

    var style = '.chat {list-style:none; margin:0; padding: 0;}';
    style += 'p{margin-block-end:0px; margin-block-start:0px}';
    style += '.chat li{border-top: 1px dotted #B3A9A9; padding: 10px;}';
    style += '.btn{display: none;}';

    this.utils.printHtmlWithCSS(style, "Messaggi", html);
  }

  /**
   * 
   * Elimina messaggio
   */
  deleteMessage(dismissMethod: string, item: Message, status) {
    if (dismissMethod == 'close') return;

    this.rest.messagePrivateDelete(item.message_id, status).subscribe(res => {
      const index: number = this.messages.indexOf(item);
      if (index !== -1) {
        this.messages.splice(index, 1);
      }

      this.toastr.success('Messaggio eliminato con successo!');
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }
}
