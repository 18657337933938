import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { User, UserCategory } from 'src/app/models/user';
import { AppConstants } from 'src/app/utils/constants';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  users: User[];
  isUpdate: boolean;

  role: number;
  office_id: number;
  buildingName: String;

  constructor(
    private router: Router,
    private rest: RestService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public globalEventsManager: GlobalEventsManager
  ) {
    this.office_id = this.route.snapshot.params['id'];
    this.role = this.globalEventsManager.getUserRole();
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [5, 6] }],
      order: [[4, "desc"], [0, "asc"]],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.usersList();
  }

  /**
    * Lista
    */
  usersList() {
    this.isLoading = true;

    this.rest.usersList(this.office_id).subscribe(res => {
      this.isLoading = false;

      this.users = res.data.users;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  concatenateAttributes(categories: UserCategory[]) {
    return categories.map(item => item.name).join(', ');
  }

  /**
   * Dettaglio
   */
  edit(item: User) {
    this.router.navigate([`/office/${this.office_id}/users/${item.user_id}`]);
  }

  /**
   * Elimina
   */
  delete(item: User) {
    this.rest.userDelete(item.user_id).subscribe(res => {
      this.toastr.success('Utente eliminato con successo!');
      this.usersList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * 
   * Imposta un cliente come favorito
   */
  setFavorite(item: User) {
    item.favorite = item.favorite == 0 ? 1 : 0;
    this.rest.userFavoriteSet(item.user_id, item.favorite).subscribe();
    this.rerenderDataTable();
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
