import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import bsCustomFileInput from 'bs-custom-file-input';
import { Message } from 'src/app/models/message';
import { AppConstants } from 'src/app/utils/constants';
import { User, UserCategory } from 'src/app/models/user';
declare var $: any;

@Component({
  selector: 'app-messages-public',
  templateUrl: './messages-public.component.html',
  styleUrls: ['./messages-public.component.css']
})
export class MessagesPublicComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = false;
  saving: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  messages: Message[];
  users: User[];
  userCategories: UserCategory[];
  isUpdate: boolean;
  submitted = false;
  form: FormGroup;
  fileAttachment: File;

  office_id: number;
  media_delete: number = 0; // da non cancellare. 1 da cancellare 

  types = [
    { id: 0, title: "Tutti" },
    { id: 1, title: "Scegli i clienti" },
    { id: 2, title: "Scegli le categorie" }
  ];

  constructor(private router: Router, private rest: RestService, private toastr: ToastrService, private route: ActivatedRoute, private formBuilder: FormBuilder) {
    this.office_id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [3, 4] }],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = this.formBuilder.group({
      message_id: [null],
      message: ['', Validators.required],
      attachment: [null],
      media_url: [null],
      type: [null],
      users: [[]],
      categories: [[]]
    });

    this.messagesList();
    this.usersList();
    this.userCategoriesList();
  }

  ngAfterViewInit() {
    bsCustomFileInput.init();
  }

  get f() {
    return this.form.controls;
  }

  /**
    * Lista
    */
  messagesList() {
    this.isLoading = true;

    this.rest.messagesPublicList(this.office_id).subscribe(res => {
      this.isLoading = false;

      this.messages = res.data.messages;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Lista clienti
   */
  usersList() {
    this.rest.usersList(this.office_id).subscribe(res => {
      this.users = res.data.users;
    });
  }

  /**
 * Lista categorie clienti
 */
  userCategoriesList() {
    this.rest.categoriesUsersList().subscribe(res => {
      this.userCategories = res.data.categories;
    });
  }

  handleFileCover(files: FileList) {
    if (files && files.length > 0) {
      this.fileAttachment = files[0];
    }
  }

  deleteMedia() {
    this.media_delete = 1;
    this.saveMessage();
  }

  editMessage(item: Message) {
    this.submitted = false;

    this.media_delete = 0;

    this.form.reset();

    if (item) {
      this.form.patchValue({
        message_id: item.message_id,
        message: item.message,
        media_url: item.media_url,
        type: item.categories.length > 0 ? this.types[2].id : item.users.length > 0 ? this.types[1].id : this.types[0].id,
        users: item.categories.length > 0 ? [] : item.users?.map(u => u.user_id),
        categories: item.categories?.map(c => c.category_id)
      });
    } else {
      this.form.patchValue({
        type: this.types[0].id,
        users: [],
        categories: []
      });
    }

    $("#modal").modal('show');
  }

  public getIds(users: User[]) {
    var ids = [];
    for (let u of users) {
      ids.push(u.user_id.toString());
    }
    return ids;
  }

  onChaneType(item) {
    switch (item.id) {
      case 0: //tutti
        this.f.users.patchValue([]);
        this.f.categories.patchValue([]);
        break;
      case 1: //scelta utenti
        this.f.categories.patchValue([]);
        break;
      case 2: //scelta categorie
        this.f.users.patchValue([]);
        break
    }
  }

  /**
   * Elimina
   */
  delete(item: Message) {
    this.rest.messagePublicDelete(item.message_id).subscribe(res => {
      this.toastr.success('Messaggio eliminato con successo!');
      this.messagesList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  saveMessage() {
    this.submitted = true;

    var type = this.f.type.value;
    var users = this.f.users.value;
    var categories = this.f.categories.value;

    if (type == 1 && users.length == 0) {
      this.f.users.setErrors({ 'required': true })
    } else if (type == 2 && categories.length == 0) {
      this.f.categories.setErrors({ 'required': true })
    } else {
      this.f.users.setErrors(null);
      this.f.categories.setErrors(null);
    }

    if (this.form.invalid) {
      return;
    }

    this.saving = true;

    var messageID = this.f.message_id.value;

    const formData = new FormData();

    formData.append("message", this.f.message.value);
    formData.append("media_delete", this.media_delete.toString());
    if (this.fileAttachment) formData.append("file", this.fileAttachment);

    if (this.f.type.value == 1) {
      formData.append("users", this.f.users.value);
    } else if (this.f.type.value == 2) {
      formData.append("categories", this.f.categories.value);
    }

    if (messageID) {
      formData.append("message_id", messageID.toString());

      this.rest.messagePublicEdit(formData).subscribe(res => {
        this.saving = false;
        $("#modal").modal('hide');
        this.toastr.success('Messaggio modificato con successo!');
        this.messagesList();
      }, error => {
        this.saving = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      formData.append("office_id", this.office_id.toString());

      this.rest.messagePublicSend(formData).subscribe(res => {
        this.saving = false;
        $("#modal").modal('hide');
        this.toastr.success('Messaggio aggiunto con successo!');
        this.messagesList();
      }, error => {
        this.saving = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    bsCustomFileInput.destroy();
  }
}
