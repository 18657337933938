import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { LoginComponent } from './authentication/login/login.component';
import { SignupComponent } from './authentication/signup/signup.component';
import { MainComponent } from './main/main.component';
import { RedirecthGuard } from './utils/guards/redirect.guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', redirectTo: '', pathMatch: 'full', canActivate: [RedirecthGuard] },
      { path: 'administrator', loadChildren: './administrator/administrator.module#AdministratorModule' },
      { path: 'office/:id', loadChildren: './office/office.module#OfficeModule' }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot_password', component: ForgotPasswordComponent },
  { path: 'change_password', component: ChangePasswordComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
