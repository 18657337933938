import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppConstants } from '../constants';
import { AuthenticationService } from '../services/authentication.service';
import { RestService } from '../services/rest.service';

@Injectable({
    providedIn: 'root'
})
export class RedirecthGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthenticationService, private rest: RestService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.auth.currentUserValue;

        if (currentUser) {
            if (currentUser.type_id == AppConstants.USER_TYPE_SUPER_ADMIN) {
                this.router.navigate(['/administrator']);
                return true;
            } else if (currentUser.type_id == AppConstants.USER_TYPE_OFFICE) {
                this.router.navigate([`/office/${currentUser.office_id}`]);
                return true;
            } else if (currentUser.type_id == AppConstants.USER_TYPE_MANAGER) {
                this.rest.officeDetail(this.auth.currentUserValue.office_id).subscribe(res => {
                    this.auth.saveOffice(res.data.office);
                });

                this.router.navigate([`/office/${currentUser.office_id}/news`]);
                return true;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
