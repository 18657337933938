import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Office } from 'src/app/models/office';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { AppConstants } from 'src/app/utils/constants';

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.css']
})
export class OfficesComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  offices: Office[];
  isUpdate: boolean;

  constructor(private router: Router, private rest: RestService, private toastr: ToastrService, public globalManager: GlobalEventsManager) { }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [
        { targets: 1, type: 'date-euro' },
        { orderable: false, targets: [7] }],
      order: [2, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.officesList();
  }
  /**
    * Lista
    */
  officesList() {
    this.isLoading = true;

    this.rest.officesList().subscribe(res => {
      this.isLoading = false;

      this.offices = res.data.offices;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Dettaglio
   */
  edit(item: Office) {
    // this.router.navigate([`/administrator/admins/${item.user_id}`]);
    // if (this.globalManager.isUserSuperAdmin()) {
    //   // se sono un superadmin allora uso questo path
    //   this.router.navigate([`/administrator/admins/${item.admin_id}/building/${item.building_id}`]);
    // } else {
    // admin di condominio
    // set nome del condominio nell header
    this.globalManager.setOfficeName(item.name);

    // this.globalManager.showBuildingNavBar(true); //mostro menu laterale
    this.router.navigate([`/office/${item.office_id}/profile`]);
    // }
  }

  /**
   * Elimina
   */
  checkPassword(value) {
    if (value != AppConstants.ADMIN_PASSWORD) return "Password errata";
  }

  delete(item: Office) {
    this.rest.officeDelete(item.office_id).subscribe(res => {
      this.toastr.success('Ufficio eliminato con successo!');
      this.officesList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
