import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  // field è il campo sul quale cercare
  transform(items: any[], fields: string, value: string): any[] {
    if (!items) return [];
    if (!value) return items;
    
    return items.filter(str => {
      const fields_array = fields.split(",");

      var item_found = false;

      fields_array.forEach(function (column_key) {
        if (str[column_key]?.toLowerCase().includes(value?.toLowerCase())) {
          item_found = true;
        }
      });

      return item_found;
    });
  }
}
