import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import { DocumentTool } from 'src/app/models/document-tool';

@Component({
  selector: 'app-office-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isLoadingDocuments: boolean;
  documents: DocumentTool[];
  isUpdate: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private rest: RestService, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [
        { targets: 0, type: 'date-euro' },
        { orderable: false, targets: [3] }],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.toolsList();
  }

  /**
* Lista 
*/
  toolsList() {
    this.isLoadingDocuments = true;

    this.rest.toolsList().subscribe(res => {
      this.isLoadingDocuments = false;

      this.documents = res.data.tools;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
