import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfficeComponent } from './office.component';
import { ProfileComponent } from './profile/profile.component';
import { OfficeRoutingModule } from './office-routing.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxColorsModule } from 'ngx-colors';
import { NewsComponent } from './news/news.component';
import { EmailComponent } from './email/email.component';
import { LinksComponent } from './links/links.component';
import { UsersComponent } from './users/users.component';
import { ManagersComponent } from './managers/managers.component';
import { ServicesComponent } from './services/services.component';
import { ContactsComponent } from './contacts/contacts.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { ManagerDetailComponent } from './managers/manager-detail/manager-detail.component';
import { MessagesPublicComponent } from './messages-public/messages-public.component';
import { MessagesPrivateComponent } from './messages-private/messages-private.component';
import { MessagesPrivateDetailComponent } from './messages-private/messages-private-detail/messages-private-detail.component';
import { PipesModule } from '../utils/pipes/pipes.module';
import { BulletinsComponent } from './bulletins/bulletins.component';
import { DocumentsComponent } from './users/documents/documents.component';
import { ToolsComponent } from './tools/tools.component';
import { AgendaComponent } from './agenda/agenda.component';
import { CategoriesComponent } from './users/categories/categories.component';
import { CategoryDetailComponent } from './users/categories/category-detail/category-detail.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [OfficeComponent, ProfileComponent, NewsComponent, EmailComponent, LinksComponent, UsersComponent, ManagersComponent, ServicesComponent,
    ContactsComponent, BulletinsComponent, UserDetailComponent, ManagerDetailComponent, MessagesPublicComponent, MessagesPrivateComponent, MessagesPrivateDetailComponent, 
    DocumentsComponent, ToolsComponent, AgendaComponent, CategoriesComponent, CategoryDetailComponent],
  imports: [
    CommonModule,
    OfficeRoutingModule,
    SweetAlert2Module,
    DataTablesModule,
    ReactiveFormsModule,
    UiSwitchModule,
    NgxColorsModule,
    PipesModule,
    NgSelectModule
  ]
})
export class OfficeModule { }
