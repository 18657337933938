<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <button class="btn btn-default btn-sm mb-1" [routerLink]="['/office', office_id, 'managers']"><i class="fas fa-arrow-left mr-2"></i>Torna indietro</button>

                <form [formGroup]="form">
                    <div class="card card-info card-outline">
                        <div class="card-body">
                            <h2 class="text-center mb-3"><span class="badge badge-secondary">Credenziali</span></h2>

                            <div class="row justify-content-md-center">
                                <div class="form-group col-12 col-lg-4">
                                    <label>Email</label>
                                    <input type="text" class="form-control" id="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [readonly]="user_id">
                                    <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                    <div *ngIf="f.email.errors?.email" class="invalid-feedback">Email non corretta
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-4">
                                    <label>Password</label>
                                    <input type="password" class="form-control" id="password" formControlName="password"
                                        [ngClass]="{ 'is-invalid': (submitted || submittedCredentials) && f.password.errors }">
                                    <div *ngIf="f.password.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                    <div *ngIf="f.password.errors?.minlength" class="invalid-feedback">Lunghezza min. 8
                                        caratteri
                                    </div>
                                    <div *ngIf="f.password.errors?.maxlength" class="invalid-feedback">Lunghezza max. 45
                                        caratteri
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="user_id" class="text-center mb-3">
                                <button class="btn btn-info" (click)="sendCredentials()" [disabled]="sendingCredentials"> <span *ngIf="sendingCredentials"
                                        class="spinner-border spinner-border-sm mr-1"></span>
                                    Invia credenziali</button>
                            </div>

                            <hr>

                            <h2 class="text-center mb-4"><span class="badge badge-secondary">Anagrafica</span>
                            </h2>

                            <div class="row">
                                <div class="form-group col-12 col-lg-8">
                                    <label>Nome</label>
                                    <input type="text" class="form-control" id="fullname" formControlName="fullname" [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }">
                                    <div *ngIf="f.fullname.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>
                                <div class="form-group col-12 col-lg-4">
                                    <label>Telefono <small>(opzionale)</small></label>
                                    <input type="text" class="form-control" id="phone" formControlName="phone">
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()" [disabled]="saving"> <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>

            </div>

            <div *ngIf="user_id" class="col-12">
                <div class="col-12">
                    <div class="text-center">
                        <h2><span class="badge badge-secondary">Clienti</span></h2>
                    </div>
                </div>

                <div class="col-12">
                    <div class="mb-3">
                        <button class="btn btn-info" (click)="showAddUserPopup()"><i class="fas fa-plus mr-1"></i>
                            Aggiungi Cliente</button>
                    </div>

                    <div class="card card-info card-outline">
                        <div class="card-body table-responsive">
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                                <thead>
                                    <tr>
                                        <th>Codice</th>
                                        <th>Nome</th>
                                        <th>Email</th>
                                        <th>Telefono</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of clients">
                                        <td>{{item.code}}</td>
                                        <td>{{item.fullname}}</td>
                                        <td>{{item.email}}</td>
                                        <td>{{item.phone}}</td>

                                        <td class="text-right">
                                            <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i class="fas fa-trash mr-1"></i>
                                                Elimina</button>

                                            <swal #deleteSwal title="Elimina" text="Vuoi rimuovere questo cliente dal manager?" icon="warning"
                                                [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" (confirm)="delete(item)"></swal>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <form [formGroup]="form_client" id="form_client">
                <div class="modal-body">
                    <div class="row">
                        <input formControlName="category_id" type="hidden">

                        <div *ngIf="availableClients?.length > 0; else other_content" class="form-group col-12">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Codice</th>
                                        <th>Nome</th>
                                        <th>Email</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of availableClients">
                                        <td>{{item.code}}</td>
                                        <td>{{item.fullname}}</td>
                                        <td>{{item.email}}</td>

                                        <td class="text-right">
                                            <button type="submit" class="btn btn-info" (click)="add(item)" [disabled]="sendingClient">
                                                <span *ngIf="sendingClient" class="spinner-border spinner-border-sm mr-1"></span>
                                                Aggiungi</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <ng-template #other_content>Nessun cliente disponibile</ng-template>

                    </div>
                </div>

                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                </div>
            </form>

        </div>
    </div>
</div>