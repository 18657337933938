import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalEventsManager } from '../providers/global-events-manager';
import { AuthenticationService } from '../services/authentication.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthenticationService, public globalManager: GlobalEventsManager) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.auth.currentUserValue;

    if (currentUser) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.type_id) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }

      // se la route necessita del PREMIUM e non sono superadmin, bloccare link
      // controllo anche se la data è scaduta
      if (route.data.premium && !this.auth.isPremium() && !this.globalManager.isUserSuperAdmin()) {
        Swal.fire('ABBONAMENTO', 'Hai attivato il piano Base, utilizzabile come vetrina, per attuare il piano <b>Premium</b> o rinnovare contatta THnet al numero <a href="tel:392.96.24.402">392.96.24.402</a> o su <a href="mailto:app@mio-studio.it">app@mio-studio.it</a>', 'info');
        return false;
      }

      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
