<div class="login-page">

    <div class="login-box">
        <div class="login-logo">
            <img src="assets/img/logo.png" alt="MioStudio Logo Large">
        </div>

        <div class="card">
            <div class="card-body login-card-body">
                <form [formGroup]="form">
                    <div class="text-center">
                        <label>Inserisci una nuova password</label>
                    </div>

                    <div class="input-group mb-3">
                        <input formControlName="password" type="password" class="form-control" placeholder="Password"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <div *ngIf="f.password.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        <div *ngIf="f.password.errors?.minlength" class="invalid-feedback">Lunghezza min. 8 caratteri
                        </div>
                        <div *ngIf="f.password.errors?.maxlength" class="invalid-feedback">Lunghezza max. 45 caratteri
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-4">
                            <button [disabled]="isDisabled" class="btn btn-info btn-block" (click)="changePassword()"
                                type="submit">
                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
                                Salva
                            </button>
                        </div>
                    </div>
                    <div *ngIf="message_error" class="text-center alert alert-danger mt-3 mb-0">{{message_error}}</div>
                    <div *ngIf="message_success" class="text-center alert alert-success mt-3 mb-0">{{message_success}}
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>