import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { UserCategory } from 'src/app/models/user';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  role: number;
  office_id: number;
  categories: UserCategory[];
  isUpdate: boolean;

  constructor(private router: Router, private rest: RestService, private toastr: ToastrService,
    private route: ActivatedRoute, private globalEventsManager: GlobalEventsManager) {
    this.office_id = this.route.snapshot.params['id'];
    this.role = this.globalEventsManager.getUserRole();
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [2] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.categoriesList();
  }

  /**
    * Lista
    */
  categoriesList() {
    this.isLoading = true;
      
    this.rest.categoriesUsersList().subscribe(res => {
      this.isLoading = false;

      this.categories = res.data.categories;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Dettaglio
   */
  edit(item: UserCategory) {
    // this.router.navigate([`/office/${this.office_id}/users/${item.user_id}`]);
  }

  /**
   * Elimina
   */
  delete(item: UserCategory) {
    this.rest.categoriesUsersDelete(item.category_id).subscribe(res => {
      this.toastr.success('Categoria eliminata con successo!');
      this.categoriesList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}