<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <form [formGroup]="form">
                    <div class="card card-info card-outline">
                        <div class="card-body">
                            <div class="row">

                                <div class="form-group col-6">
                                    <div class="text-center mb-3">
                                        <img height="250" src="{{f.logo_a_url.value ? f.logo_a_url.value :'assets/img/image_placeholder.png'}}" alt="Logo picture">
                                        <div>
                                            <div *ngIf="fileLogoA"><small>Dimensione: {{fileLogoA?.size / 1024 / 1024 | number : '1.2-2'}} MB</small></div>
                                            <small>L'immagine non deve superare 2MB</small>
                                        </div>
                                    </div>

                                    <div class="input-group">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="logo_a" formControlName="logo_a" accept="image/*" (change)="handleFileLogoA($event.target.files)">
                                            <label class="custom-file-label" for="logo_a">Seleziona
                                                logo</label>
                                        </div>
                                        <div *ngIf="f.logo_a_url.value" class="input-group-append">
                                            <button type="button" class="btn btn-danger" (click)="removeLogoA()">Elimina</button>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group col-6">
                                    <div class="text-center mb-3">
                                        <img height="250" src="{{f.logo_b_url.value ? f.logo_b_url.value :'assets/img/image_placeholder.png'}}" alt="Cover picture">
                                        <div>
                                            <div *ngIf="fileLogoB"><small>Dimensione: {{fileLogoB?.size / 1024 / 1024 | number : '1.2-2'}} MB</small></div>
                                            <small>L'immagine non deve superare 2MB</small>
                                        </div>
                                    </div>

                                    <div class="input-group">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="logo_b" formControlName="logo_b" accept="image/*" (change)="handleFileLogoB($event.target.files)">
                                            <label class="custom-file-label" for="logo_b">Seleziona
                                                immagine aggiuntiva</label>
                                        </div>
                                        <div *ngIf="f.logo_b_url.value" class="input-group-append">
                                            <button type="button" class="btn btn-danger" (click)="removeLogoB()">Elimina</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-4">
                                    <label>Nome</label>
                                    <input type="text" class="form-control" id="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="f.name.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>
                                <div class="form-group col-12 col-lg-4">
                                    <label>Indirizzo</label>
                                    <input type="text" class="form-control" id="address" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                                    <div *ngIf="f.address.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>
                                <div class="form-group col-12 col-lg-4">
                                    <label>Email</label>
                                    <input type="text" class="form-control" id="email" formControlName="email" readonly [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <small>Questo indirizzo email viene utilizzato solo per effettuare l'accesso in app e ricezione notifiche</small>
                                    <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>
                                <div class="form-group col-12">
                                    <label for="description">Descrizione</label>
                                    <textarea class="form-control" id="description" formControlName="description"></textarea>
                                </div>
                                <div class="form-group col-12 col-lg-4">
                                    <label>Codice di riferimento/promozione</label>
                                    <input type="text" class="form-control" id="coupon" formControlName="coupon" readonly>
                                </div>

                                <div class="form-group col-12">
                                    <div class="callout callout-info">
                                        <h5><b>Informazioni</b></h5>
                                        Per mostrare i numeri di telefono e gli indirizzi email in app, bisogna inserirli compilando le relative sezioni '<b>Email</b>' e '<b>Numeri Utili</b>'
                                        raggiungibili dal menu laterale.
                                    </div>
                                </div>
                            </div>

                            <div class="text-center mb-4">
                                <h2><span class="badge badge-secondary">Gestione dei Colori</span></h2>
                                <p>Imposta i colori per il tuo ufficio nell'app mobile</p>
                            </div>

                            <div class="row">
                                <div class="col-lg-3 col-12">
                                    <div class="info-box">
                                        <ngx-colors ngx-colors-trigger format="hex" acceptLabel="Seleziona" [style.background]="f.color_1.value" class="info-box-icon" formControlName="color_1">
                                        </ngx-colors>
                                        <div class="info-box-content">
                                            <span class="info-box-number">Colore 1</span>
                                            <small>Colore di sfondo dei pulsanti, intestazioni e toolbar</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-12">
                                    <div class="info-box">
                                        <ngx-colors ngx-colors-trigger format="hex" acceptLabel="Seleziona" [style.background]="f.color_2.value" class="info-box-icon" formControlName="color_2">
                                        </ngx-colors>
                                        <div class="info-box-content">
                                            <span class="info-box-number">Colore 2</span>
                                            <small>Colore del testo dei pulsanti, intestazioni e toolbar</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-12">
                                    <div class="info-box">
                                        <ngx-colors ngx-colors-trigger format="hex" acceptLabel="Seleziona" [style.background]="f.color_3.value" class="info-box-icon" formControlName="color_3">
                                        </ngx-colors>
                                        <div class="info-box-content">
                                            <span class="info-box-number">Colore 3</span>
                                            <small>Colore delle icone della sezione attiva</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-12">
                                    <div class="info-box">
                                        <ngx-colors ngx-colors-trigger format="hex" acceptLabel="Seleziona" [style.background]="f.color_4.value" class="info-box-icon" formControlName="color_4">
                                        </ngx-colors>
                                        <div class="info-box-content">
                                            <span class="info-box-number">Colore 4</span>
                                            <small>Colore delle icone della sezione NON attiva</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center mt-2">
                                <ui-switch formControlName="enabled"></ui-switch>
                            </div>

                            <div class="row mt-4">
                                <div class="col-12 col-lg-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="text-center">
                                                <h2><span class="badge badge-secondary">Funzionalità extra</span></h2>
                                                <p>Attiva/disattiva queste funzionalità nell'app mobile</p>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
                                                <div>
                                                    <strong>Bacheca/News</strong>
                                                    <p class="text-muted">
                                                        Questa funzionalità permette di avere una bacheca notizie in app
                                                    </p>
                                                </div>
                                                <p class="d-flex flex-column text-right">
                                                    <ui-switch formControlName="news_enabled"></ui-switch>
                                                </p>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
                                                <div>
                                                    <strong>Agenda</strong>
                                                    <p class="text-muted">
                                                        Questa funzionalità permette di avere un'agenda appuntamenti in app
                                                    </p>
                                                </div>
                                                <p class="d-flex flex-column text-right">
                                                    <ui-switch formControlName="request_appointment_enabled"></ui-switch>
                                                </p>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <strong>Caricamento Documenti</strong>
                                                    <p class="text-muted">
                                                        Questa funzionalità permette ai clienti di caricare dei documenti privati in app
                                                    </p>
                                                </div>
                                                <p class="d-flex flex-column text-right">
                                                    <ui-switch formControlName="upload_document_user_enabled"></ui-switch>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="text-center">
                                                <h2><span class="badge badge-secondary">Gestione abbonamento</span></h2>
                                                <p>Informazioni abbonamento</p>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-center">
                                                <ui-switch formControlName="premium" checkedLabel="Piano PREMIUM" uncheckedLabel="Piano BASE"></ui-switch>

                                                <p class="d-flex flex-column text-right">
                                                    <label>Scadenza abbonamento</label>
                                                    <input formControlName="premium_expiration_date" type="date" class="form-control" />
                                                </p>
                                            </div>


                                            <!-- <div class="align-items-center h-100">
                                                <div class="col-3 mx-auto text-center">
                                                    <label>Scadenza abbonamento</label>
                                                    <input formControlName="premium_expiration_date" type="date" class="form-control" />
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()" [disabled]="saving"> <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>

            </div>

        </div>
    </div>
</section>