<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" (click)="toggleMenuSidebar.emit()"><i class="fas fa-bars"></i></a>
      </li>
    </ul>
  
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          {{email}}
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <div class="user-panel text-center">
            <div class="info">
              <h6 class="d-block">{{email}}</h6>
              <span class="badge badge-info">{{role}}</span>
            </div>
          </div>
          <div class="dropdown-divider"></div>
          <a class="nav-link text-center" (click)="logout()">
            <i class="fas fa-power-off"></i> Esci
          </a>
        </div>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->