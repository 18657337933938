import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAgendaDatesComponent } from 'src/app/modals/modal-agenda-dates/modal-agenda-dates.component';
import { Agenda, AgendaException, AgendaSlot, AgendaWeekdaySlots } from 'src/app/models/agenda';
import * as moment from 'moment';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/utils/constants';
import { Subject } from 'rxjs';
import { ModalAgendaExceptionSlotsComponent } from 'src/app/modals/modal-agenda-exception-slots/modal-agenda-exception-slots.component';
import { ModalAgendaDateAddComponent } from 'src/app/modals/modal-agenda-date-add/modal-agenda-date-add.component';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { off } from 'process';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElementBookings: DataTableDirective;
  @ViewChild(DataTableDirective) dtElementExceptions: DataTableDirective;

  dtOptionsBookings: DataTables.Settings = {};
  dtTriggerBookings: Subject<any> = new Subject();
  dtOptionsExceptions: DataTables.Settings = {};
  dtTriggerExceptions: Subject<any> = new Subject();

  office_id: number;

  weekdays = AppConstants.WEEKDAYS;
  items: AgendaWeekdaySlots[];
  isLoadingSlots: boolean;

  exceptions: AgendaException[];
  isLoadingExceptions: boolean;

  bookings: Agenda[];
  isLoadingBookings: boolean;
  isUpdatebookings: boolean;
  isUpdateExceptions: boolean;

  form: FormGroup;
  saving = false;

  constructor(private route: ActivatedRoute, private rest: RestService, private modalService: NgbModal, private toastr: ToastrService,
    private formBuilder: FormBuilder, private auth: AuthenticationService) {
    this.office_id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.dtOptionsBookings = {
      columnDefs: [
        { targets: 0, type: 'date-euro' },
        { orderable: false, targets: [5] }
      ],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.dtOptionsExceptions = {
      columnDefs: [
        { targets: 0, type: 'date-euro' },
        { orderable: false, targets: [1, 2] }
      ],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = this.formBuilder.group({
      agenda_info: [this.auth.getOffice().agenda_info]
    });

    this.getAgendaSlotsList();
    this.getAgendaExceptions();
    this.getBookings();
  }

  get f() {
    return this.form.controls;
  }

  getAgendaSlotsList() {
    this.isLoadingSlots = true;

    this.rest.agendaSlotsList().subscribe(res => {
      this.isLoadingSlots = false;
      this.items = res.data.weekdays;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  getAgendaExceptions() {
    this.isLoadingExceptions = true;

    this.rest.agendaExceptionsList().subscribe(res => {
      this.isLoadingExceptions = false;
      this.exceptions = res.data.dates;

      if (this.isUpdateExceptions) {
        this.rerenderExceptionsDataTable();
      } else {
        this.isUpdateExceptions = true;
        this.dtTriggerExceptions.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  getBookings() {
    this.isLoadingBookings = true;

    this.rest.agendaBookingsList().subscribe(res => {
      this.isLoadingBookings = false;
      this.bookings = res.data.bookings;

      if (this.isUpdatebookings) {
        this.rerenderBookingsDataTable();
      } else {
        this.isUpdatebookings = true;
        this.dtTriggerBookings.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  getDate(item) {
    moment.locale("it");
    return moment(item, "YYYY-MM-DD").format("dddd DD-MM-YYYY");
  }

  getSlot(slot: string) {
    return moment(slot, "HH:mm:ss").format("HH:mm");
  }

  getExceptionSlots(items: AgendaSlot[]) {
    if (items.length == 0) return "Chiuso";

    var times = [];
    items.forEach(i => {
      var startTime = this.getSlot(i.start_time);
      var endTime = this.getSlot(i.end_time);
      times.push(startTime + '-' + endTime);
    });

    return times;
  }

  getSlots(weekday: number) {
    var times = [];

    this.items?.find(i => {
      return i.weekday == weekday;
    })?.slots.forEach(i => {
      var startTime = moment(i.start_time, "HH:mm:ss").format("HH:mm");
      var endTime = moment(i.end_time, "HH:mm:ss").format("HH:mm");
      times.push(startTime + '-' + endTime);
    });

    return times;
  }

  editSlots(weekday: number) {
    const modalRef = this.modalService.open(ModalAgendaDatesComponent);
    modalRef.componentInstance.item = this.items.find(i => { return i.weekday == weekday; });
    modalRef.result.then((result) => { }, (reason) => { });
  }

  editExceptionslots(exception: AgendaException) {
    const modalRef = this.modalService.open(ModalAgendaExceptionSlotsComponent);
    modalRef.componentInstance.item = exception;
    modalRef.result.then((result) => {
    }, (reason) => {
      // click su annulla. refresh lista
      this.getAgendaExceptions();
    });
  }

  addExceptionDate() {
    const modalRef = this.modalService.open(ModalAgendaDateAddComponent);
    modalRef.result.then((result) => {
      this.rest.agendaExceptionDateAdd(result).subscribe(res => {
        var exception = new AgendaException();
        exception.date = result;
        exception.slots = [];
        this.editExceptionslots(exception);
        this.getAgendaExceptions();
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });

    }, (reason) => { });
  }

  /**
   * Eliminazione di un appuntamento
   * @param item 
   */
  deleteBooking(item: Agenda) {
    this.rest.agendaBookingDelete(item.booking_id).subscribe(res => {
      this.toastr.success('Appuntamento eliminato con successo!');
      this.getBookings();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  deleteException(item: AgendaException) {
    this.rest.agendaExceptionDateDelete(item.date).subscribe(res => {
      this.toastr.success('Eccezione eliminata con successo!');
      this.getAgendaExceptions();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  saveAgenda() {
    var bodyData = {
      office_id: this.office_id.toString(),
      agenda_info: this.f.agenda_info.value.trim()
    };

    this.saving = true;

    this.rest.agendaInfoEdit(bodyData).subscribe(res => {
      var office = this.auth.getOffice();
      office.agenda_info = bodyData.agenda_info;;
      this.auth.saveOffice(office);

      this.saving = false;
      this.toastr.success('Agenda aggiornata con successo!');
    }, error => {
      this.saving = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderBookingsDataTable(): void {
    this.dtElementBookings.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTriggerBookings.next();
    });
  }
  rerenderExceptionsDataTable(): void {
    this.dtElementExceptions.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTriggerExceptions.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTriggerBookings.unsubscribe();
    this.dtTriggerExceptions.unsubscribe();
  }
}
