import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Office } from 'src/app/models/office';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { RestService } from 'src/app/utils/services/rest.service';
declare var $: any;
import * as moment from 'moment';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  @ViewChild('deleteSwal')
  public readonly deleteSwal!: SwalComponent;

  office_id: number;
  isLoading: boolean;
  form: FormGroup;
  office: Office;
  submitted = false;
  saving = false;

  fileLogoA: File;
  fileLogoB: File;
  deleteLogoA: boolean;
  deleteLogoB: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private rest: RestService,
    private toastr: ToastrService, private formBuilder: FormBuilder, public globalManager: GlobalEventsManager,
    private auth: AuthenticationService) {

    this.office_id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      enabled: [{ value: false, disabled: this.globalManager.isOffice() }],
      premium: [{ value: false, disabled: this.globalManager.isOffice() }],
      name: [null, Validators.required],
      address: [null, Validators.required],
      email: [null, Validators.required],
      description: [''],
      coupon: [''],
      logo_a_url: [null],
      logo_b_url: [null],
      logo_a: [null],
      logo_b: [null],
      color_1: [null],
      color_2: [null],
      color_3: [null],
      color_4: [null],
      premium_expiration_date: [{ value: null, disabled: this.globalManager.isOffice() }],
      news_enabled: [false],
      request_appointment_enabled: [false],
      upload_document_user_enabled: [false]
    });

    this.officeDetail();
  }

  get f() {
    return this.form.controls;
  }

  officeDetail() {
    this.isLoading = true;

    this.rest.officeDetail(this.office_id).subscribe(res => {
      this.isLoading = false;

      this.office = res.data.office;

      this.form.patchValue({
        enabled: this.office.enabled == 1,
        name: this.office.name,
        address: this.office.address,
        email: this.office.email,
        description: this.office.description,
        coupon: this.office.coupon,
        premium: this.office.premium == 1,
        logo_a_url: this.office.logo_url,
        logo_b_url: this.office.logo2_url,
        color_1: this.office.color_1,
        color_2: this.office.color_2,
        color_3: this.office.color_3,
        color_4: this.office.color_4,
        news_enabled: this.office.news_enabled == 1,
        request_appointment_enabled: this.office.request_appointment_enabled == 1,
        upload_document_user_enabled: this.office.upload_document_user_enabled == 1,
        premium_expiration_date: moment(this.office.premium_expiration_date * 1000).format("YYYY-MM-DD")
      })

      // salvataggio in locale
      this.auth.saveOffice(this.office);

      if (!this.auth.isPremium() && !this.globalManager.isUserSuperAdmin()) {
        Swal.fire('ABBONAMENTO', 'Hai attivato il piano Base, utilizzabile come vetrina, per attuare il piano <b>Premium</b> o rinnovare contatta THnet al numero <a href="tel:392.96.24.402">392.96.24.402</a> o su <a href="mailto:app@mio-studio.it">app@mio-studio.it</a>', 'info');
      }
    });

  }

  handleFileLogoA(files: FileList) {
    if (files && files.length > 0) {
      this.fileLogoA = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.f.logo_a_url.setValue(event.target.result);
      };

      reader.readAsDataURL(this.fileLogoA);
    }
  }

  handleFileLogoB(files: FileList) {
    if (files && files.length > 0) {
      this.fileLogoB = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.f.logo_b_url.setValue(event.target.result);
      };

      reader.readAsDataURL(this.fileLogoB);
    }
  }

  removeLogoA() {
    this.fileLogoA = null;
    this.f.logo_a_url.setValue(null);
    this.deleteLogoA = true;
  }

  removeLogoB() {
    this.fileLogoB = null;
    this.f.logo_b_url.setValue(null);
    this.deleteLogoB = true;
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.fileLogoA?.size / 1024 / 1024 > 2) {
      this.toastr.error('Immagine troppo grande!');
      return;
    }

    if (this.fileLogoB?.size / 1024 / 1024 > 2) {
      this.toastr.error('Immagine troppo grande!');
      return;
    }

    const formData = new FormData();

    formData.append("office_id", this.office_id.toString());
    formData.append("name", this.f.name.value);
    formData.append("description", this.f.description.value);
    formData.append("address", this.f.address.value);
    formData.append("email", this.f.email.value);

    formData.append("premium", this.f.premium.value ? '1' : '0');
    if (this.f.premium.value) formData.append("premium_expiration_date", moment(this.f.premium_expiration_date.value).format('DD/MM/YYYY').toString());


    if (this.f.color_1.value) formData.append("color_1", this.f.color_1.value);
    if (this.f.color_2.value) formData.append("color_2", this.f.color_2.value);
    if (this.f.color_3.value) formData.append("color_3", this.f.color_3.value);
    if (this.f.color_4.value) formData.append("color_4", this.f.color_4.value);

    if (this.fileLogoA) formData.append("logo", this.fileLogoA);
    if (this.fileLogoB) formData.append("logo2", this.fileLogoB);
    formData.append("logo_delete", this.deleteLogoA ? '1' : '0');
    formData.append("logo2_delete", this.deleteLogoB ? '1' : '0');

    formData.append("enabled", this.f.enabled.value ? '1' : '0');
    formData.append("news_enabled", this.f.news_enabled.value ? '1' : '0');
    formData.append("request_appointment_enabled", this.f.request_appointment_enabled.value ? '1' : '0');
    formData.append("upload_document_user_enabled", this.f.upload_document_user_enabled.value ? '1' : '0');

    this.saving = true;

    this.rest.officeEdit(formData).subscribe(res => {
      this.saving = false;
      this.toastr.success('Profilo aggiornato con successo!');
    }, error => {
      this.saving = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }
}

