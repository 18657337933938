import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministratorComponent } from './administrator.component';
import { AdministratorRoutingModule } from './administator-routing.module';
import { NewsComponent } from './news/news.component';
import { DataTablesModule } from 'angular-datatables';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OfficesComponent } from './offices/offices.component';
import { ChangePasswordAdminComponent } from './change-password-admin/change-password-admin.component';
import { SettingsComponent } from './settings/settings.component';
import { ToolsComponent } from './tools/tools.component';

@NgModule({
  declarations: [AdministratorComponent, NewsComponent, NewsDetailComponent, OfficesComponent, ChangePasswordAdminComponent, SettingsComponent, ToolsComponent],
  imports: [
    CommonModule,
    AdministratorRoutingModule,
    DataTablesModule,
    SweetAlert2Module,
    ReactiveFormsModule
  ]
})
export class AdministratorModule { }
