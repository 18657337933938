<div class="login-logo">
    <img src="assets/img/logo.png" alt="MioStudio Logo Large">
</div>

<div class="card">
    <div class="card-body login-card-body">
        <p class="login-box-msg"><b>Non sei ancora affiliato?<br>Registrati al servizio</b></p>
        <div class="text-center">
            <small>Per poter abilitare il tuo studio, dopo aver effettuato la registrazione, accedi al pannello web al seguente <a href="https://webpanel.mio-studio.it/" target="_blank">link</a> e
                completa il tuo
                profilo.</small>
        </div>

        <form [formGroup]="form" class="mt-2">
            <div class="form-group ">
                <label>Nome studio</label>
                <input formControlName="office_name" type="text" class="form-control" placeholder="Inserisci il nome dello studio" [ngClass]="{ 'is-invalid': submitted && f.office_name.errors }" />
                <div *ngIf="f.office_name.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            </div>

            <div class="form-group ">
                <label>Email</label>
                <input formControlName="email" type="email" class="form-control" placeholder="Inserisci un indirizzo email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            </div>

            <div class="form-group ">
                <label>Telefono</label>
                <input formControlName="phone" type="tel" class="form-control" placeholder="Inserisci un numero di telefono" />
            </div>

            <div class="form-group ">
                <label>Password (min 8 caratteri)</label>
                <input formControlName="password" type="password" class="form-control" placeholder="Inserisci una password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="f.password.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            </div>

            <div class="form-group ">
                <label>Codice di riferimento/promozione</label>
                <input formControlName="coupon" type="text" class="form-control" placeholder="Inserisci un codice" />
            </div>

            <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" formControlName="policy" [ngClass]="{ 'is-invalid': submitted && f.policy.errors }">
                <label class="form-check-label">Accetto le <a target="_blank" href="https://www.thnet.it/tcpdf/thtopdf/th_informativa_partner.php?id=2540">Privacy Policy e i Termini di
                        Utilizzo</a></label>
                <div *ngIf="f.policy.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            </div>

            <div class="form-group ">
                <button [disabled]="loading" class="btn btn-info btn-block" (click)="signup()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Registrati
                </button>
            </div>
        </form>

        <p class="mt-3 mb-1">
            <a [routerLink]="['/login']">Torna indietro</a>
        </p>
    </div>
</div>