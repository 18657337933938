<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <button class="btn btn-default btn-sm mb-1" [routerLink]="['/office', office_id, 'users']"><i
                        class="fas fa-arrow-left mr-2"></i>Torna indietro</button>

                <div>
                    <button *ngIf="role === 2" class="btn btn-info mb-3"
                        [routerLink]="['/office', office_id, 'users', 'categories', 'new']"><i
                            class="fas fa-plus mr-1"></i>
                        Aggiungi Categoria</button>
                </div>

                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th class="text-center">Clienti associati</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of categories">
                                    <td>{{item.name}}</td>
                                    <td class="text-center">{{item.users_count}}</td>

                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm"
                                            [routerLink]="['/office', office_id, 'users', 'categories', item.category_id]"><i
                                                class="fas fa-pen mr-1"></i> Modifica</button>

                                        <button *ngIf="role === 2" class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questa categoria?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>