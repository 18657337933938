import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  loading = false;
  form: FormGroup;
  submitted = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(
      document.querySelector('body'),
      'register-page'
    );
    this.renderer.addClass(
      document.querySelector('app-root'),
      'register-box'
    );

    this.form = new FormGroup({
      office_name: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      phone: new FormControl(null),
      password: new FormControl(null, Validators.required),
      coupon: new FormControl(null),
      policy: new FormControl(false, Validators.requiredTrue)
    });
  }

  get f() {
    return this.form.controls;
  }

  signup() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    var name = this.f.office_name.value;
    var email = this.f.email.value;
    var phone = this.f.phone.value;
    var password = this.f.password.value;
    var coupon = this.f.coupon.value;
    
    this.authService.signup(email, password, name, phone, coupon).subscribe(res => {
      this.loading = false;
      this.toastr.success('Registrazione completata con successo!');
      this.router.navigate(['/login']);
    }, error => {
      this.loading = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('body'),
      'register-page'
    );
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-box'
    );
  }
}
