import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgendaSlot } from 'src/app/models/agenda';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-agenda-slot-add',
  templateUrl: './modal-agenda-slot-add.component.html',
  styleUrls: ['./modal-agenda-slot-add.component.css']
})
export class ModalAgendaSlotAddComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      start_time: new FormControl(null, Validators.required),
      end_time: new FormControl(null, Validators.required)
    });
  }

  get f() {
    return this.form.controls;
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    var slot = new AgendaSlot();
    slot.start_time = moment(this.f.start_time.value).format("HH:mm:00");
    slot.end_time = moment(this.f.end_time.value).format("HH:mm:00");

    this.activeModal.close(slot)
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
