<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <button class="btn btn-default btn-sm mb-3"
                    [routerLink]="['/office', office_id, 'users', 'categories']"><i
                        class="fas fa-arrow-left mr-2"></i>Torna indietro</button>

                <form [formGroup]="form">
                    <div class="card card-info card-outline">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label>Nome</label>
                                    <input formControlName="name" type="text" class="form-control"
                                        placeholder="Inserisci un nome"
                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <div *ngIf="f.name.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>
                            </div>

                            <div class="text-center">
                                <button type="submit" class="btn btn-info" (click)="save()" [disabled]="saving"> <span
                                        *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                                    Salva</button>
                            </div>
                        </div>
                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>

                <div *ngIf="category_id" class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Codice</th>
                                    <th>Nome</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of users">
                                    <td>{{item.code}}</td>
                                    <td>{{item.fullname}}</td>
                                    <td>{{item.email}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>