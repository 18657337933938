import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();

  email: String;
  role: String;

  constructor(    
    private router: Router,
    private authService: AuthenticationService,
    private globalManager: GlobalEventsManager) { }

  ngOnInit() {
    this.email = this.authService.currentUserValue.code;
    this.role = this.globalManager.getUserTypeLogged()[0];
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
