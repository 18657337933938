<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <button class="btn btn-default btn-sm mb-1" [routerLink]="['/administrator', 'news']"><i class="fas fa-arrow-left mr-2"></i>Torna indietro</button>

                <form [formGroup]="form">
                    <div class="card card-info card-outline">
                        <div class="card-body">
                            <div class="row justify-content-md-center">
                                <div class="form-group col-4">
                                    <div class="text-center mb-3">
                                        <img class="img-fluid" src="{{f.image_url.value ? f.image_url.value :'assets/img/image_placeholder.png'}}" alt="News Image">
                                    </div>

                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="image" formControlName="image" accept="image/*" (change)="handleFileImage($event.target.files)">
                                        <label class="custom-file-label" for="image" [ngClass]="{ 'is-invalid': submitted && f.image.errors }">Seleziona immagine
                                            di
                                            copertina</label>
                                        <div *ngIf="f.image.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-8">
                                    <label>Titolo</label>
                                    <input type="text" class="form-control" id="title" formControlName="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                                    <div *ngIf="f.title.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>
                                <div class="form-group col-12 col-lg-4">
                                    <label>Chi può visualizzare</label>
                                    <div class="select2-info">
                                        <select id="enabled_user_types" formControlName="enabled_user_types" class="form-control" data-dropdown-css-class="select2-info"
                                            data-placeholder="Scegli una o più categorie" multiple style="width: 100%;" [ngClass]="{ 'is-invalid': submitted && f.enabled_user_types.errors }">
                                        </select>
                                        <div *ngIf="f.enabled_user_types.errors?.required" class="invalid-feedback">
                                            Campo obbligatorio</div>
                                    </div>

                                </div>

                                <div class="form-group col-12">
                                    <label for="description">Descrizione</label>
                                    <textarea class="form-control" id="description" formControlName="description" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                                    <div *ngIf="f.description.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>

                                <div class="form-group col-12">
                                    <div class="btn btn-default btn-file">
                                        <i class="fas fa-paperclip"></i> Aggiungi Allegato
                                        <input type="file" name="attachment" #attachments (change)="onFileAttachmentChanged($event)">
                                    </div>
                                </div>

                                <div *ngIf="attachmentsList.length" class="form-group col-12">
                                    <ul class="mailbox-attachments d-flex align-items-stretch clearfix">
                                        <li *ngFor="let item of attachmentsList;let index = index">
                                            <span class="mailbox-attachment-icon"><i class="far fa-file"></i></span>

                                            <div class="mailbox-attachment-info text-truncate">
                                                <a (click)="item.attachment_id || $event.preventDefault()" [href]="item.media_url" class="mailbox-attachment-name" target="_blank"><i
                                                        class="fas fa-paperclip"></i>
                                                    {{item.media_url}}</a>
                                                <span class="mailbox-attachment-size clearfix mt-2 text-center">
                                                    <button class="btn btn-danger btn-sm" (click)="removeAttachmentSelectedFile(index)"><i class="far fa-trash-alt mr-2"></i>Elimina</button>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()" [disabled]="saving"> <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>