import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConstants } from '../utils/constants';
import { AuthGuard } from '../utils/guards/auth.guard';
import { BulletinsComponent } from './bulletins/bulletins.component';
import { ContactsComponent } from './contacts/contacts.component';
import { EmailComponent } from './email/email.component';
import { LinksComponent } from './links/links.component';
import { MessagesPrivateDetailComponent } from './messages-private/messages-private-detail/messages-private-detail.component';
import { MessagesPrivateComponent } from './messages-private/messages-private.component';
import { MessagesPublicComponent } from './messages-public/messages-public.component';
import { NewsComponent } from './news/news.component';
import { ProfileComponent } from './profile/profile.component';
import { ServicesComponent } from './services/services.component';
import { DocumentsComponent } from './users/documents/documents.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { UsersComponent } from './users/users.component';
import { ManagersComponent } from './managers/managers.component';
import { ManagerDetailComponent } from './managers/manager-detail/manager-detail.component';
import { ToolsComponent } from './tools/tools.component';
import { AgendaComponent } from './agenda/agenda.component';
import { CategoriesComponent } from './users/categories/categories.component';
import { CategoryDetailComponent } from './users/categories/category-detail/category-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'profile', pathMatch: 'full' },
  { path: 'profile', component: ProfileComponent, data: { roles: [AppConstants.USER_TYPE_SUPER_ADMIN, AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Profilo' }, canActivate: [AuthGuard] },
  { path: 'news', component: NewsComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Comunicazioni' }, canActivate: [AuthGuard] },
  { path: 'email', component: EmailComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Indirizzi Email' }, canActivate: [AuthGuard] },
  { path: 'links', component: LinksComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Link Utili' }, canActivate: [AuthGuard] },
  { path: 'services', component: ServicesComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Servizi' }, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Clienti', premium: true }, canActivate: [AuthGuard] },
  { path: 'users/new', component: UserDetailComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Nuovo Cliente', premium: true }, canActivate: [AuthGuard] },
  { path: 'users/categories', component: CategoriesComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Gestione Categorie', premium: true }, canActivate: [AuthGuard] },
  { path: 'users/categories/new', component: CategoryDetailComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Gestione Categorie', premium: true }, canActivate: [AuthGuard] },
  { path: 'users/categories/:category_id', component: CategoryDetailComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Gestione Categorie', premium: true }, canActivate: [AuthGuard] },
  { path: 'users/:user_id', component: UserDetailComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Profilo Cliente', premium: true }, canActivate: [AuthGuard] },
  { path: 'users/:user_id/documents/:category_id', component: DocumentsComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Documenti', premium: true }, canActivate: [AuthGuard] },
  { path: 'bulletins', component: BulletinsComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Bacheca', premium: true }, canActivate: [AuthGuard] },
  { path: 'contacts', component: ContactsComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Numeri Utili' }, canActivate: [AuthGuard] },
  { path: 'messages/public', component: MessagesPublicComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Messaggi Pubblici', premium: true }, canActivate: [AuthGuard] },
  { path: 'messages/private', component: MessagesPrivateComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Messaggi Privati', premium: true }, canActivate: [AuthGuard] },
  { path: 'messages/private/user/:user_id', component: MessagesPrivateDetailComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE, AppConstants.USER_TYPE_MANAGER], breadcrumb: 'Messaggi', premium: true }, canActivate: [AuthGuard] },
  { path: 'managers', component: ManagersComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Gestori', premium: true }, canActivate: [AuthGuard] },
  { path: 'managers/new', component: ManagerDetailComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Nuovo Gestore', premium: true }, canActivate: [AuthGuard] },
  { path: 'managers/:user_id', component: ManagerDetailComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Profilo Gestore', premium: true }, canActivate: [AuthGuard] },
  { path: 'tools', component: ToolsComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Strumenti' }, canActivate: [AuthGuard] },
  { path: 'agenda', component: AgendaComponent, data: { roles: [AppConstants.USER_TYPE_OFFICE], breadcrumb: 'Agenda' }, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficeRoutingModule { }
