<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12 col-md-9">
                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Codice Cliente</th>
                                    <th>Nome Cliente</th>
                                    <th>Ultimo Messaggio</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of messages">
                                    <td>{{item.code}}</td>
                                    <td>{{item.fullname}}</td>

                                    <td>
                                        <span *ngIf="item.last_message">{{item.last_message}}</span>
                                        <span *ngIf="!item.last_message">Allegato</span>
                                    </td>

                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm"
                                            [routerLink]="['/office', office_id, 'messages', 'private', 'user', item.user_id]">
                                            <i class="fas fa-comments mr-1"></i>Messaggi
                                            <span *ngIf="item.counter >0"
                                                class="ml-2 right badge badge-danger">{{item.counter}}</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-3">
                <div class="card card-info card-outline">
                    <div class="card-body">
                        <div class="input-group mb-3">
                            <input type="search" class="form-control" placeholder="Cerca un cliente"
                                [(ngModel)]="searchText">
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-search"></span>
                                </div>
                            </div>
                        </div>

                        <div class="users">
                            <div *ngIf="!isLoadingUsers">
                                <table class="table table-striped ">
                                    <tbody>
                                        <tr *ngFor="let item of users | search:'code,fullname': searchText">
                                            <td>
                                                <div>{{item.code}}</div>
                                                <div *ngIf="item.fullname"><small>{{item.fullname}}</small></div>
                                            </td>
                                            <td class="align-middle">
                                                <button class="btn btn-info btn-sm float-right"
                                                    [routerLink]="['/office', office_id, 'messages', 'private','user', item.user_id]"><i
                                                        class="fas fa-comment mr-1"></i>
                                                    Scrivi</button>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="isLoadingUsers" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>