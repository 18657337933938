<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <form [formGroup]="form">
                    <div class="card card-info card-outline">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label>Nuova Password</label>
                                    <input type="text" class="form-control" id="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                    <div *ngIf="f.password.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>
                                <div class="form-group col-12">
                                    <label>Ripeti Password</label>
                                    <input type="text" class="form-control" id="password_confirm" formControlName="password_confirm"
                                        [ngClass]="{ 'is-invalid': submitted && f.password_confirm.errors }">
                                    <div *ngIf="f.password_confirm.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                    <div *ngIf="f.password_confirm.hasError('mismatch')" class="invalid-feedback">Le password devono coincidere</div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="checkPassword()" [disabled]="saving"><span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>