import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { News } from 'src/app/models/news';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/utils/constants';
import bsCustomFileInput from 'bs-custom-file-input';
import { NewsAttachment } from 'src/app/models/news-attachment';
declare var $: any;

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('attachments') attachment: any;

  isLoading: boolean = false;
  submitted = false;
  saving = false;

  form: FormGroup;

  id: number;
  news: News;

  attachmentsList: NewsAttachment[] = [];
  fileAttachmentsList: File[] = [];
  fileImage: File;
  fileRemovedIds: number[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private rest: RestService, private toastr: ToastrService, private formBuilder: FormBuilder) {
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      image: [null],
      enabled_user_types: [null],
      image_url: [null]
    });

    $('#enabled_user_types').select2({
      allowClear: true
    });
    for (let u of AppConstants.USER_TYPES) {
      // escludere superadmin
      if (u.type_id == AppConstants.USER_TYPE_SUPER_ADMIN) continue;
      var data = { id: u.type_id, text: u.type_name };
      var newOption = new Option(data.text, data.id.toString(), false, false);
      $('#enabled_user_types').append(newOption).trigger('change');
    }

    if (this.id) {
      this.newsDetail();
    }
  }

  ngAfterViewInit() {
    bsCustomFileInput.init();
  }

  get f() {
    return this.form.controls;
  }

  newsDetail() {
    this.isLoading = true;

    this.rest.newsDetail(this.id).subscribe(res => {
      this.isLoading = false;

      this.news = res.data.news;
      this.attachmentsList = this.news.attachments;

      this.form.setValue({
        image: null,
        image_url: this.news.image_url,
        title: this.news.title,
        description: this.news.description,
        enabled_user_types: this.news.users_types
      })

      $('#enabled_user_types').val(this.news.users_types).trigger("change");
    });
  }

  /**
   * 
   * @param event Aggiunta file allegati
   */
  onFileAttachmentChanged(event: any) {
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      var selectedFile = event.target.files[i];

      var attachment = new NewsAttachment();
      attachment.media_url = selectedFile.name;
      this.attachmentsList.push(attachment);

      this.fileAttachmentsList.push(selectedFile);
    }

    this.attachment.nativeElement.value = '';
  }

  /**
   * Rimozione di un allegato
   * @param index 
   */
  removeAttachmentSelectedFile(index) {
    if (this.attachmentsList[index].attachment_id) {
      this.fileRemovedIds.push(this.attachmentsList[index].attachment_id);
    }

    this.fileAttachmentsList.splice(index, 1);
    this.attachmentsList.splice(index, 1);
  }

  /**
   * Listener immagine cover
   * @param files 
   */
  handleFileImage(files: FileList) {
    if (files && files.length > 0) {
      this.fileImage = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.f.image_url.setValue(event.target.result);
      };
      reader.readAsDataURL(this.fileImage);

    }
  }

  save() {
    this.submitted = true;

    var enabledUserTypes = $("#enabled_user_types").select2('data');
    if (enabledUserTypes.length == 0) {
      this.f.enabled_user_types.setErrors({ 'required': true })
    } else {
      this.f.enabled_user_types.setErrors(null)
    }

    // solo se è un nuovo post faccio controllo image obbligatorio
    if (!this.id && !this.fileImage) {
      this.f.image.setErrors({ 'required': true })
    } else {
      this.f.image.setErrors(null)
    }

    if (this.form.invalid) {
      return;
    }

    var usersSelectedIds = [];
    for (let u of enabledUserTypes) {
      usersSelectedIds.push(u.id);
    }

    const formData = new FormData();

    formData.append("title", this.f.title.value);
    formData.append("description", this.f.description.value);
    formData.append("enabled_user_types", usersSelectedIds.toString());
    if (this.fileImage) formData.append("image", this.fileImage);

    if (this.fileAttachmentsList) {
      for (let i = 0; i < this.fileAttachmentsList.length; i++) {
        formData.append('attachments', this.fileAttachmentsList[i]);
      }
    }

    this.saving = true;

    if (this.id) {
      // se ci sono allegati da rimuovere
      if (this.fileRemovedIds) {
        formData.append('delete_attachments_ids', this.fileRemovedIds.toString());
      }

      formData.append("news_id", this.id.toString());

      this.rest.newsEdit(formData).subscribe(res => {
        this.saving = false;
        this.toastr.success('Comunicazione aggiornata con successo!');
        this.router.navigate([`/administrator/news`]);
      }, error => {
        this.saving = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.newsAdd(formData).subscribe(res => {
        this.saving = false;
        this.toastr.success('Comunicazione creata con successo!');
        this.router.navigate([`/administrator/news`]);
      }, error => {
        this.saving = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

}