import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { RestService } from 'src/app/utils/services/rest.service';
import bsCustomFileInput from 'bs-custom-file-input';
import { DocumentTool } from 'src/app/models/document-tool';
declare var $: any;

@Component({
  selector: 'app-admin-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  submitted = false;
  saving = false;

  form: FormGroup;

  documents: DocumentTool[];
  isUpdate: boolean;
  fileAttachment: File;
  isLoadingDocuments: boolean;
  submittedDocument = false;
  sendingDocument = false;

  constructor(private router: Router, private route: ActivatedRoute, private rest: RestService, private toastr: ToastrService, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [
        { targets: 0, type: 'date-euro' },
        { orderable: false, targets: [3] }],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = this.formBuilder.group({
      tool_id: [null],
      title: ['', Validators.required],
      description: [''],
      attachment: [null]
    });


    this.toolsList();
  }

  ngAfterViewInit() {
    bsCustomFileInput.init();
  }

  get f() {
    return this.form.controls;
  }

  /**
* Lista 
*/
  toolsList() {
    this.isLoadingDocuments = true;

    this.rest.toolsList().subscribe(res => {
      this.isLoadingDocuments = false;

      this.documents = res.data.tools;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  handleFile(files: FileList) {
    if (files && files.length > 0) {
      this.fileAttachment = files[0];
    }
  }

  saveDocument() {
    var toolID = this.f.tool_id.value;

    this.submittedDocument = true;

    if (!toolID && !this.fileAttachment) {
      this.f.attachment.setErrors({ 'required': true })
    } else {
      this.f.attachment.setErrors(null)
    }

    if (this.form.invalid) {
      return;
    }

    this.sendingDocument = true;

    const formData = new FormData();
    formData.append("title", this.f.title.value);

    if (this.f.description.value) formData.append("description", this.f.description.value);
    if (this.fileAttachment) formData.append("file", this.fileAttachment);

    if (toolID) {
      formData.append("tool_id", toolID);

      this.rest.toolEdit(formData).subscribe(res => {
        this.sendingDocument = false;

        $("#modal").modal('hide');
        this.toastr.success('Documento modificato con successo!');
        this.toolsList();
      }, error => {
        this.sendingDocument = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.toolAdd(formData).subscribe(res => {
        this.sendingDocument = false;

        $("#modal").modal('hide');
        this.toastr.success('Documento aggiunto con successo!');
        this.toolsList();
      }, error => {
        this.sendingDocument = false;

        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }


  editDocument(item: DocumentTool) {
    this.submittedDocument = false;

    $('#form')[0].reset();

    if (item) {
      this.form.patchValue({
        tool_id: item.tool_id,
        title: item.title,
        description: item.description
      });
    }

    $("#modal").modal('show');
  }

  deleteDocument(item: DocumentTool) {
    this.rest.toolDelete(item.tool_id).subscribe(res => {
      this.toastr.success('Documento eliminato con successo!');
      this.toolsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    // bsCustomFileInput.destroy();
  }
}
