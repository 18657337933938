import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user';

declare var $: any;

@Component({
  selector: 'app-manager-detail',
  templateUrl: './manager-detail.component.html',
  styleUrls: ['./manager-detail.component.css']
})
export class ManagerDetailComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isLoading: boolean;
  submitted = false;
  saving = false;

  form: FormGroup;
  form_client: FormGroup;

  office_id: number;
  user_id: number;

  user: User;
  clients: User[];

  availableClients: User[];
  sendingClient = false;

  sendingCredentials = false;
  submittedCredentials = false;

  isUpdate: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private rest: RestService, private toastr: ToastrService, private formBuilder: FormBuilder) {
    this.office_id = this.route.snapshot.params['id'];
    this.user_id = this.route.snapshot.params['user_id'];
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [4] }],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null],
      fullname: [null, Validators.required],
      phone: [null]
    });

    this.form_client = this.formBuilder.group({
      category_id: [null]
    });

    if (this.user_id) {
      this.managerDetail();
    }
  }

  get f() {
    return this.form.controls;
  }

  managerDetail() {
    this.isLoading = true;

    this.rest.managerDetail(this.user_id).subscribe(res => {
      this.isLoading = false;

      this.user = res.data.manager;
      this.clients = res.data.users;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }

      this.form.patchValue({
        fullname: this.user.fullname,
        email: this.user.email,
        phone: this.user.phone
      })
    });
  }

  // Salvataggio del profilo utente
  save() {
    this.submitted = true;

    if (this.user_id && this.f.password) {
      this.f.password.setValidators([Validators.minLength(8), Validators.maxLength(45)]);
      this.f.password.updateValueAndValidity();
    } else {
      this.f.password.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(45)]);
      this.f.password.updateValueAndValidity();
    }

    if (this.form.invalid) {
      return;
    }

    var bodyData = {
      fullname: this.f.fullname.value.trim(),
      email: this.f.email.value.trim(),
      phone: this.f.phone.value?.trim()
    };

    if (this.f.password.value) {
      bodyData['password'] = this.f.password.value;
    }

    this.saving = true;

    if (this.user_id) {
      bodyData['user_id'] = this.user_id;

      this.rest.managerEdit(bodyData).subscribe(res => {
        this.saving = false;
        this.toastr.success('Profilo aggiornato con successo!');
      }, error => {
        this.saving = false;
        if (error == 3) {
          this.toastr.warning('Email già esistente!');
        } else {
          this.toastr.error('Si è verificato un errore, riprova!');
        }
      });
    } else {
      bodyData['office_id'] = this.office_id;

      this.rest.managerAdd(bodyData).subscribe(res => {
        this.saving = false;
        this.toastr.success('Profilo creato con successo!');
        this.router.navigate([`/office/${this.office_id}/managers`]);
      }, error => {
        this.saving = false;
        if (error == 3) {
          this.toastr.warning('Email già esistente!');
        } else {
          this.toastr.error('Si è verificato un errore, riprova!');
        }
      });
    }
  }

  // invio delle credenziali alla mail dell'utente
  sendCredentials() {
    this.submittedCredentials = true;

    var password = this.f.password.value;
    var email = this.f.email.value;

    if (!password) {
      this.f.password.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(45)]);
      this.f.password.updateValueAndValidity();
    }
    if (!email) {
      this.f.email.setValidators([Validators.required, Validators.email]);
      this.f.email.updateValueAndValidity();
    }

    if (this.form.invalid) {
      return;
    }

    this.sendingCredentials = true;

    this.rest.userChangePassword(this.user_id, password, email).subscribe(res => {
      this.sendingCredentials = false;
      this.submittedCredentials = false;
      this.toastr.success('Password modificata con successo e credenziali inviate all\'utente!');
    }, error => {
      this.sendingCredentials = false;
      this.submittedCredentials = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });

  }

  showAddUserPopup() {
    this.isLoading = true;

    this.rest.usersList(this.office_id).subscribe(res => {
      this.isLoading = false;

      let currentUserIds = this.clients.map(function (a) { return a.user_id; });
      this.availableClients = res.data.users.filter(function (a) { return !currentUserIds.includes(a.user_id); });

      $('#form_client')[0].reset();
      $("#modal").modal('show');
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  add(item: User) {
    this.sendingClient = true;
    this.rest.managerUsersAdd(item.user_id, this.user.user_id).subscribe(res => {
      this.sendingClient = false;
      $("#modal").modal('hide');
      this.toastr.success('Cliente aggiunto con successo!');
      this.managerDetail();
    }, error => {
      this.sendingClient = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  delete(item: User) {
    this.rest.managerUsersDelete(item.user_id, this.user.user_id).subscribe(res => {
      this.toastr.success('Cliente rimosso con successo!');
      this.managerDetail();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
