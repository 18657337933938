<form [formGroup]="form">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-12">
                <label for="start_time">Orario di inizio</label>
                <input [owlDateTime]="dt_start_time" [owlDateTimeTrigger]="dt_start_time" type="text" class="form-control" id="start_time" formControlName="start_time" readonly="readonly"
                    [ngClass]="{ 'is-invalid': submitted && f.start_time.errors }">
                <owl-date-time [pickerType]="'timer'" #dt_start_time></owl-date-time>
                <div *ngIf="f.start_time.errors?.required" class="invalid-feedback">Campo
                    obbligatorio</div>
            </div>

            <div class="form-group col-12">
                <label for="end_time">Orario di fine</label>
                <input [owlDateTime]="dt_end_time" [owlDateTimeTrigger]="dt_end_time" type="text" class="form-control" id="end_time" formControlName="end_time" readonly="readonly"
                    [ngClass]="{ 'is-invalid': submitted && f.end_time.errors }">
                <owl-date-time [pickerType]="'timer'" #dt_end_time></owl-date-time>
                <div *ngIf="f.end_time.errors?.required" class="invalid-feedback">Campo
                    obbligatorio</div>
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button type="submit" class="btn btn-info" (click)="save()">Salva</button>
    </div>
</form>