<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button *ngIf="role === 2" class="btn btn-info mb-3"
                    [routerLink]="['/office', office_id, 'managers', 'new']"><i
                        class="fas fa-plus mr-1"></i>
                    Aggiungi Gestore</button>

                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Nome</th>
                                    <th>Telefono</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of managers">
                                    <td>{{item.email}}</td>
                                    <td>{{item.fullname}}</td>
                                    <td>{{item.phone}}</td>
                                    
                                    <td class="text-right">
                                        <button class="btn btn-warning btn-sm" (click)="edit(item)"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>

                                        <button *ngIf="role === 2" class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo manager?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>