<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button class="btn btn-info mb-3" (click)="editMessage(null)"><i class="fas fa-plus mr-1"></i>
                    Nuovo Messaggio</button>

                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Data di pubblicazione</th>
                                    <th>Messaggio</th>
                                    <th style="width: 10%">Allegato</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of messages">
                                    <td>{{item.message_id}}</td>
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.message}}</td>
                                    <td>
                                        <span *ngIf="item.media_url">
                                            <a href="{{item.media_url}}" class="btn btn-default btn-sm"><i
                                                    class="fas fa-download mr-2"></i>Allegato</a>
                                        </span>
                                    </td>

                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="editMessage(item)"><i
                                                class="fas fa-pen mr-1"></i> Modifica</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo messaggio?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="form" id="form">
                <div class="modal-body">
                    <div class="row">
                        <input formControlName="message_id" type="hidden">
                        <input formControlName="media_url" type="hidden">

                        <div class="form-group col-12">
                            <label>Messaggio</label>
                            <textarea placeholder="Scrivi un messaggio..." formControlName="message" cols="40" rows="5"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                            <div *ngIf="f.message.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                        <div class="form-group col-12">
                            <label>A chi vuoi inviare il messaggio</label>
                            <ng-select formControlName="type" [items]="types" bindLabel="title" bindValue="id"
                                (change)="onChaneType($event)"></ng-select>
                        </div>

                        <div class="form-group col-12">
                            <label>Seleziona i clienti</label>
                            <ng-select formControlName="users" [multiple]="true" [items]="users"
                                [ngClass]="{ 'is-invalid': submitted && f.users.errors }" [readonly]="f.type.value != 1"
                                bindLabel="fullname" bindValue="user_id" [virtualScroll]=true
                                notFoundText="Nessun cliente trovato">
                            </ng-select>
                            <div *ngIf="f.users.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                        <div class="form-group col-12">
                            <label>Seleziona le categorie</label>
                            <ng-select formControlName="categories" [multiple]="true" [items]="userCategories"
                                [ngClass]="{ 'is-invalid': submitted && f.categories.errors }"
                                [readonly]="f.type.value != 2" bindLabel="name" bindValue="category_id"
                                [virtualScroll]=true notFoundText="Nessuna categoria trovata">
                            </ng-select>
                            <div *ngIf="f.categories.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                        <div class="form-group col-12">
                            <label>Allegato</label>

                            <div *ngIf="!f.media_url.value" class="custom-file">
                                <input type="file" class="custom-file-input" id="attachment"
                                    formControlName="attachment" (change)="handleFileCover($event.target.files)">
                                <label class="custom-file-label" for="attachment">Aggiungi un
                                    allegato</label>
                            </div>

                            <div *ngIf="f.media_url.value">
                                <div class="btn btn-danger" (click)="deleteMedia()">
                                    <i class="fas fa-trash mr-1"></i> Elimina Allegato
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button [disabled]="saving" class="btn btn-info" (click)="saveMessage()" type="submit">
                        <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                        Invia
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>