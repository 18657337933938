import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/utils/constants';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { RestService } from 'src/app/utils/services/rest.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password-admin',
  templateUrl: './change-password-admin.component.html',
  styleUrls: ['./change-password-admin.component.css']
})
export class ChangePasswordAdminComponent implements OnInit {

  submitted = false;
  saving = false;

  form: FormGroup;

  constructor(private router: Router, private authService: AuthenticationService, private rest: RestService, private toastr: ToastrService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      password_confirm: ['', Validators.required]
    });
  }

  get f() {
    return this.form.controls;
  }

  checkPassword() {
    this.submitted = true;

    if (this.f.password.value != this.f.password_confirm.value) {
      this.f.password_confirm.setErrors({ mismatch: true });
    } else {
      this.f.password_confirm.setErrors(null);
    }

    if (this.form.invalid) {
      return;
    }

    Swal.fire({
      title: 'Nuova Password',
      text: 'Vuoi cambiare la password?',
      icon: 'warning',
      input: 'password',
      inputPlaceholder: 'Inserisci la password di sicurezza',
      showCancelButton: true,
      confirmButtonText: 'Si, continua!',
      cancelButtonText: 'No',
      inputValidator: (value) => {
        return new Promise(function (resolve) {
          if (value != AppConstants.ADMIN_PASSWORD) {
            resolve('Password errata');
          } else {
            resolve('');
          }
        });
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.save();
      }
    });
  }

  save() {
    var user_id =  this.authService.currentUserValue.user_id;
    var password = this.f.password.value;
    this.saving = true;

    this.rest.changePassword(user_id, password).subscribe(res => {
      this.saving = false;
      this.toastr.success('Password modificata con successo!');

      this.authService.logout();
      this.router.navigate(['/login']);
    }, error => {
      this.saving = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }
}
