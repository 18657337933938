<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button *ngIf="role === 2" class="btn btn-info mb-3"
                    [routerLink]="['/office', office_id, 'users', 'new']"><i class="fas fa-plus mr-1"></i>
                    Aggiungi Utente</button>
                <button *ngIf="role === 2" class="btn btn-warning mb-3"
                    [routerLink]="['/office', office_id , 'users' , 'categories']"><i class="fas fa-tasks mr-1"></i>
                    Gestione Categorie</button>

                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Codice</th>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Telefono</th>
                                    <th class="text-center">Preferito</th>
                                    <th>Categorie</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of users">
                                    <td>{{item.code}}</td>
                                    <td>{{item.fullname}}</td>
                                    <td>{{item.email}}</td>
                                    <td>{{item.phone}}</td>
                                    <td class="text-center" [ngSwitch]="item.favorite">
                                        <i *ngSwitchCase="0" class="far fa-star" (click)="setFavorite(item)"><span
                                                style="display:none;">{{item.favorite}}</span></i>
                                        <i *ngSwitchCase="1" class="fas fa-star" (click)="setFavorite(item)"><span
                                                style="display:none;">{{item.favorite}}</span></i>
                                    </td>
                                    <td>{{concatenateAttributes(item.categories)}}</td>

                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm"
                                            [routerLink]="['/office', office_id, 'messages', 'private', 'user', item.user_id]"><i
                                                class="fas fa-comment mr-1"></i> Scrivi</button>

                                        <button class="btn btn-warning btn-sm" (click)="edit(item)"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>

                                        <button *ngIf="role === 2" class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo utente?"
                                            icon="warning" input="password"
                                            inputPlaceholder="Scrivi 'elimina' per procedere"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="delete(item)"
                                            [inputValidator]="globalEventsManager.checkPassword"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>