<div class="modal-body">
    <div class="text-center">
        <h3>{{weekdays[item?.weekday]}}</h3>
    </div>

    <div class="row">
        <div class="form-group col-12">
            <div class="table-responsive">
                <div class="text-center">
                    <button class="btn btn-info mb-3" (click)="addSlot()"><i class="fas fa-plus mr-1"></i>
                        Aggiungi slot</button>
                </div>
                <table *ngIf="item?.slots.length > 0" class="table table-striped dtr-inline">
                    <thead>
                        <tr>
                            <th>Orario di inizio</th>
                            <th>Orario di fine</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of item?.slots">
                            <td>{{getSlot(item.start_time)}}</td>
                            <td>{{getSlot(item.end_time)}}</td>
                            <td class="text-right">
                                <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i class="fas fa-trash"></i> </button>

                                <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo slot ?" icon="warning"
                                    [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" (confirm)="deleteSlot(item)"></swal>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
</div>