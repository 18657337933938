import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { Router, ActivatedRouteSnapshot, ActivatedRoute, ActivationEnd } from '@angular/router';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.css']
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();

  showOfficeNavBar: boolean = false;
  role: number;
  office_id: string;

  constructor(public router: Router, private auth: AuthenticationService, private globalEventsManager: GlobalEventsManager) {
    const currentUser = this.auth.currentUserValue;
    this.role = currentUser.type_id;

    // controllo se mostrare la nav
    this.globalEventsManager.showOfficeNavBarEmitter.subscribe((mode) => {
      this.showOfficeNavBar = mode;
    });

    router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        // active sidebar with offices detail                
        if (event && event.snapshot && event.snapshot.params && event.snapshot.params.id) {
          this.office_id = event.snapshot.params.id;
          this.showOfficeNavBar = true;
        }
        if (event && event.snapshot && event.snapshot.routeConfig && event.snapshot.routeConfig.path.includes('administrator')) {
          this.showOfficeNavBar = false;
        }
      }
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }

  backHistory() {
    this.globalEventsManager.showOfficeNavBar(false);
  }
}
