import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { User } from 'src/app/models/user';
import { MessageUser } from 'src/app/models/message-user';
declare var $: any;

@Component({
  selector: 'app-messages-private',
  templateUrl: './messages-private.component.html',
  styleUrls: ['./messages-private.component.css']
})
export class MessagesPrivateComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  office_id: number;

  messages: MessageUser[];
  isUpdate: boolean;

  isLoadingUsers: boolean = true;
  searchText: string;
  users: User[];

  constructor(private rest: RestService, private toastr: ToastrService, private route: ActivatedRoute, public globalManager: GlobalEventsManager) {
    this.office_id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [3] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.messagesList();
    this.usersList();
  }

  /**
    * Lista
    */
  messagesList() {
    this.isLoading = true;

    this.rest.messagesPrivateUsersList(this.office_id).subscribe(res => {
      this.isLoading = false;

      this.messages = res.data.users;
      
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Lista di tutti gli utenti dei condomini gestiti da questo admin + tutti i partners
   */
  usersList() {
    this.isLoadingUsers = true;

    this.rest.usersList(this.office_id).subscribe(res => {
      this.isLoadingUsers = false;
      this.users = res.data.users;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
