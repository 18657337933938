import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgendaSlot } from 'src/app/models/agenda';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-agenda-date-add',
  templateUrl: './modal-agenda-date-add.component.html',
  styleUrls: ['./modal-agenda-date-add.component.css']
})
export class ModalAgendaDateAddComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      date: new FormControl(null, Validators.required),
    });
  }

  get f() {
    return this.form.controls;
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    var date = moment(this.f.date.value).format("YYYY-MM-DD");

    this.activeModal.close(date)
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
