<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <button class="btn btn-info mb-3" [routerLink]="['/administrator/news/new']"><i
                        class="fas fa-plus mr-1"></i>
                    Nuova Comunicazione</button>
               
                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Data</th>
                                    <th>Titolo</th>
                                    <th>Chi visualizza</th>
                                    <th style="width: 20%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of news">
                                    <td>{{item.news_id}}</td>
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{getUsersVisibility(item.users_types)}}</td>

                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="edit(item)"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questa comunicazione?"
                                            icon="warning"
                                            input="password"
                                            inputPlaceholder="Inserisci la password di sicurezza"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            [inputValidator]="checkPassword"
                                            (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>