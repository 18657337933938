<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <button class="btn btn-default btn-sm mb-1" [routerLink]="['/office', office_id, 'users']"><i
                        class="fas fa-arrow-left mr-2"></i>Torna indietro</button>

                <form [formGroup]="form">
                    <div class="card card-info card-outline">
                        <div class="card-body">
                            <h2 class="text-center mb-3"><span class="badge badge-secondary">Credenziali APP</span>
                            </h2>

                            <div class="row justify-content-md-center">
                                <div class="form-group col-12 col-lg-4">
                                    <label>Codice</label>
                                    <input type="text" class="form-control" id="code" formControlName="code"
                                        [ngClass]="{ 'is-invalid': submitted && f.code.errors }" [readonly]="user_id">
                                    <div *ngIf="f.code.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>

                                <div class="form-group col-12 col-lg-4">
                                    <label>Password</label>
                                    <input type="password" class="form-control" id="password" formControlName="password"
                                        [ngClass]="{ 'is-invalid': (submitted || submittedCredentials) && f.password.errors }">
                                    <div *ngIf="f.password.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                    <div *ngIf="f.password.errors?.minlength" class="invalid-feedback">Lunghezza min. 8
                                        caratteri
                                    </div>
                                    <div *ngIf="f.password.errors?.maxlength" class="invalid-feedback">Lunghezza max. 45
                                        caratteri
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="user_id" class="text-center mb-3">
                                <button class="btn btn-info" (click)="sendCredentials()"
                                    [disabled]="sendingCredentials"> <span *ngIf="sendingCredentials"
                                        class="spinner-border spinner-border-sm mr-1"></span>
                                    Invia credenziali</button>
                            </div>

                            <hr>

                            <h2 class="text-center mb-4"><span class="badge badge-secondary">Anagrafica</span>
                            </h2>

                            <div class="row">
                                <div class="form-group col-12 col-lg-4">
                                    <label>Nome <small>(opzionale)</small></label>
                                    <input type="text" class="form-control" id="fullname" formControlName="fullname">
                                </div>
                                <div class="form-group col-12 col-lg-4">
                                    <label>Email <small>(opzionale)</small></label>
                                    <input type="text" class="form-control" id="email" formControlName="email"
                                        [ngClass]="{ 'is-invalid': submittedCredentials && f.email.errors }">
                                    <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                    <div *ngIf="f.email.errors?.email" class="invalid-feedback">Email non corretta
                                    </div>
                                </div>
                                <div class="form-group col-12 col-lg-4">
                                    <label>Telefono <small>(opzionale)</small></label>
                                    <input type="text" class="form-control" id="phone" formControlName="phone">
                                </div>
                                <div class="form-group col-12 col-lg-4">
                                    <label>Categorie <small>(opzionale)</small></label>
                                    <ng-select formControlName="categories" [multiple]="true" [items]="userCategories"
                                        bindLabel="name" bindValue="category_id" [virtualScroll]=true
                                        notFoundText="Nessuna categoria trovata">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()" [disabled]="saving"> <span
                                    *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>

            </div>

            <div *ngIf="user_id" class="col-12">
                <div class="row">

                    <div class="col-12 col-md-4">
                        <div class="card card-info">
                            <div class="card-header">
                                <h3 class="card-title">Chi gestisce questo cliente</h3>

                            </div>
                            <div class="card-body table-responsive p-0">
                                <table class="table">
                                    <tbody *ngIf="managers?.length > 0; else empty">
                                        <tr *ngFor="let item of managers">
                                            <td>{{item.fullname}}</td>
                                        </tr>
                                    </tbody>

                                    <ng-template #empty>
                                        <tbody>
                                            <tr>
                                                <td>Nessun gestore</td>
                                            </tr>
                                        </tbody>
                                    </ng-template>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-8">
                        <div class="card card-info ">
                            <div class="card-header">
                                <h3 class="card-title">Documenti Privati</h3>
                            </div>

                            <div class="card-body table-responsive">
                                <div class="mb-3 text-center">
                                    <button class="btn btn-info" (click)="editCategoryDocument(null)"><i
                                            class="fas fa-plus mr-1"></i>
                                        Aggiungi Categoria</button>
                                </div>

                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table table-striped ">
                                    <thead>
                                        <tr>
                                            <th style="width: 50%">Titolo</th>
                                            <th style="width: 10%">Documenti</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of categories">
                                            <td>{{item.name}}</td>
                                            <td>{{item.count}}</td>

                                            <td class="text-right">
                                                <button class="btn btn-warning btn-sm"
                                                    (click)="openCategoryDocument(item)"><i class="fas fa-eye mr-1"></i>
                                                    Apri</button>

                                                <button class="btn btn-info btn-sm"
                                                    (click)="editCategoryDocument(item)"><i class="fas fa-pen mr-1"></i>
                                                    Modifica</button>

                                                <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                        class="fas fa-trash mr-1"></i>
                                                    Elimina</button>

                                                <swal #deleteSwal title="Elimina"
                                                    text="Vuoi eliminare questa categoria?" icon="warning"
                                                    [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                                    (confirm)="deleteCategoryDocument(item)">
                                                </swal>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div *ngIf="isLoadingCategories" class="overlay"><i
                                    class="fas fa-3x fa-sync-alt fa-spin"></i>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="form_category" id="form_category">
                <div class="modal-body">
                    <div class="row">
                        <input formControlName="category_id" type="hidden">

                        <div class="form-group col-12">
                            <label>Titolo</label>
                            <input formControlName="name" type="text" class="form-control"
                                placeholder="Inserisci il titolo della categoria"
                                [ngClass]="{ 'is-invalid': submittedCategory && f_category.name.errors }" />
                            <div *ngIf="f_category.name.errors?.required" class="invalid-feedback">Campo obbligatorio
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>

                    <button type="submit" class="btn btn-info" (click)="saveCategoryDocument()"
                        [disabled]="sendingCategory">
                        <span *ngIf="sendingCategory" class="spinner-border spinner-border-sm mr-1"></span>
                        Invia</button>

                </div>
            </form>

        </div>
    </div>
</div>