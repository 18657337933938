<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <button class="btn btn-default btn-sm mb-1" [routerLink]="['/office', office_id, 'messages', 'private']"><i class="fas fa-arrow-left mr-2"></i>Torna indietro</button>

                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="card card-info">
                            <div class="card-header">
                                <h3 class="card-title">
                                    Profilo
                                </h3>
                            </div>

                            <div class="card-body box-profile">
                                <p class="text-center">Stai scrivendo a</p>
                                <h3 class="profile-username text-center mb-0"><strong>{{user?.code}}</strong></h3>
                                <div class="text-muted text-center mb-2"><small>{{user?.fullname}}</small></div>

                                <ul class="list-group list-group-unbordered mb-3">
                                    <li *ngIf="user?.email" class="list-group-item">
                                        <b>Email</b> <a class="float-right">{{user?.email}}</a>
                                    </li>
                                    <li *ngIf="user?.phone" class="list-group-item">
                                        <b>Telefono</b> <a class="float-right">{{user?.phone}}</a>
                                    </li>
                                </ul>

                                <div class="text-center">
                                    <button class="btn btn-default btn-sm" (click)="printMessages()">Stampa</button>
                                </div>
                            </div>
                        </div>

                        <div class="card card-info">
                            <div class="card-header">
                                <h3 class="card-title">
                                    Gestori
                                </h3>
                            </div>

                            <div class="card-body table-responsive p-0">
                                <table class="table">
                                    <tbody *ngIf="managers?.length > 0; else empty">
                                        <tr *ngFor="let item of managers">
                                            <td>{{item.fullname}}</td>
                                        </tr>
                                    </tbody>

                                    <ng-template #empty>
                                        <tbody>
                                            <tr>
                                                <td>Nessun gestore</td>
                                            </tr>
                                        </tbody>
                                    </ng-template>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-9">
                        <div class="card card-info">
                            <div class="card-header">
                                <h3 class="card-title">
                                    Messaggi
                                </h3>
                            </div>

                            <div #content id="content-messages" class="card-body chat-panel-body">
                                <ul class="chat" id="chat">

                                    <ng-container #messages *ngFor="let item of messages">

                                        <ng-template [ngIf]="isMyMessage(item)" [ngIfElse]="left">
                                            <li class="right clearfix">
                                                <div class="chat-body clearfix">
                                                    <div class="header">
                                                        <p style="text-align:right;">
                                                            <small>
                                                                <span [style.color]="item.is_read == 1 ? '' : '#EEEEEE'" [ngClass]="item.is_read == 1 ? 'text-info' : ''" class="fas fa-check"></span>
                                                            </small>
                                                            <small class="text-muted" id="date">{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</small>
                                                            <small><span class="glyphicon glyphicon-time"></span></small>
                                                        </p>
                                                        <p style="text-align:right;">
                                                            <strong class="primary-font" id="fullname">
                                                                <span *ngIf="item?.type_id == 2">{{ item?.office_name }}</span>
                                                                <span *ngIf="item?.type_id == 4">{{ item?.fullname }}</span>
                                                            </strong>
                                                        </p>
                                                    </div>

                                                    <p *ngIf="item.message" id="message" style="text-align:right;">
                                                        {{item.message}}</p>

                                                    <div class="float-right">
                                                        <a *ngIf="item.media_url" href="{{item.media_url}}" class="btn btn-default btn-sm"><i class="fas fa-download mr-2"></i>Scarica
                                                            Allegato</a>
                                                        <button *ngIf="item.message" type="button" class="btn btn-default btn-sm" (click)="printMessage(item, null)"><i class="fas fa-print"></i>
                                                        </button>

                                                        <button type="button" class="btn btn-danger btn-sm" [swal]="deleteMessageSwal"><i class="fas fa-trash"></i>
                                                        </button>
                                                        <swal #deleteMessageSwal title="Elimina" text="Vuoi eliminare questo messaggio?" icon="warning"
                                                            [swalOptions]="{ allowOutsideClick: false, allowEscapeKey: false, showCloseButton:true, showCancelButton: true,  confirmButtonText: 'Per me', cancelButtonText: 'Per tutti' }"
                                                            (confirm)="deleteMessage($event, item, 0)" (dismiss)="deleteMessage($event, item, 1)"></swal>
                                                    </div>

                                                </div>
                                            </li>

                                        </ng-template>

                                        <ng-template #left>
                                            <li class="left clearfix">
                                                <div class="chat-body clearfix">
                                                    <div class="header">
                                                        <p>
                                                            <small class="text-muted" id="date">{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</small>
                                                        </p>
                                                        <p><strong class="primary-font" id="fullname">{{ user?.code }}</strong></p>
                                                    </div>
                                                    <p *ngIf="item.message" id="message">{{item.message}}</p>
                                                    <div>
                                                        <button type="button" class="btn btn-danger btn-sm" [swal]="deleteMessageSwal"><i class="fas fa-trash"></i>
                                                        </button>
                                                        <swal #deleteMessageSwal title="Elimina" text="Vuoi eliminare questo messaggio?" icon="warning"
                                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                                            (confirm)="deleteMessage($event, item, 0)"></swal>

                                                        <button *ngIf="item.message" type="button" class="btn btn-default btn-sm" (click)="printMessage(item, user.code)"><i class="fas fa-print"></i>
                                                        </button>

                                                        <a *ngIf="item.media_url" href="{{item.media_url}}" class="btn btn-default btn-sm"><i class="fas fa-download mr-2"></i>Scarica
                                                            Allegato</a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ng-template>
                                    </ng-container>
                                </ul>
                            </div>

                            <div class="card-footer">
                                <span *ngFor="let item of filesAttachment;let index = index">
                                    <button type="button" class="btn btn-default btn-sm mb-2" (click)="removeAttachment(index)"><i class="fas fa-trash mr-1"></i>
                                        {{item.name}}</button>
                                </span>
                                <form [formGroup]="form">
                                    <div class="input-group">
                                        <span class="input-group-prepend">
                                            <div class="btn btn-default btn-file">
                                                <i class="fas fa-paperclip"></i> Allegato
                                                <input type="file" id="cover" multiple name="attachment" #attachment (change)="handleFileAttachment($event.target.files)">
                                            </div>
                                        </span>
                                        <input type="text" name="message" placeholder="Scrivi un messaggio ..." class="form-control" formControlName="message"
                                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                                        <span class="input-group-append">
                                            <button type="submit" class="btn btn-info" (click)="sendMessage()" [disabled]="saving">
                                                <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                                                Invia</button>


                                        </span>
                                    </div>
                                </form>
                            </div>

                            <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>