import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalEventsManager } from 'src/app/utils/providers/global-events-manager';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-managers',
  templateUrl: './managers.component.html',
  styleUrls: ['./managers.component.css']
})
export class ManagersComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  managers: User[];
  isUpdate: boolean;

  role: number;
  office_id: number;
  buildingName: String;

  constructor(private router: Router, private rest: RestService, private toastr: ToastrService, private route: ActivatedRoute, private globalEventsManager: GlobalEventsManager) {
    this.office_id = this.route.snapshot.params['id'];
    this.role = this.globalEventsManager.getUserRole();
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [{ orderable: false, targets: [3] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.managersList();
  }

  /**
    * Lista
    */
  managersList() {
    this.isLoading = true;

    this.rest.managersList(this.office_id).subscribe(res => {
      this.isLoading = false;

      this.managers = res.data.managers;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Dettaglio
   */
  edit(item: User) {
    this.router.navigate([`/office/${this.office_id}/managers/${item.user_id}`]);
  }

  /**
   * Elimina
   */
  delete(item: User) {
    this.rest.managerDelete(item.user_id).subscribe(res => {
      this.toastr.success('Manager eliminato con successo!');
      this.managersList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * 
   */

   setFavorite(item: User) {
    item.favorite = item.favorite == 0 ? 1 : 0;
    this.rest.userFavoriteSet(item.user_id, item.favorite).subscribe();
    this.rerenderDataTable();
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
