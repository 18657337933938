<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <form [formGroup]="form">
                    <div class="card card-info card-outline">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label>Questo testo sarà visibile dai clienti in app in fase di prenotazione appuntamenti.</label>
                                    <textarea placeholder="Inserisci un testo..." class="form-control" id="agenda_info" formControlName="agenda_info"></textarea>
                                </div>
                            </div>

                            <div class="text-center">
                                <button type="submit" class="btn btn-info" (click)="saveAgenda()" [disabled]="saving"> <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
                                    Salva</button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Giorno della settimana</th>
                                    <th>Orari</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let w of weekdays; let i = index">
                                    <td>{{w}}</td>
                                    <td>{{getSlots(i)}}</td>

                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="editSlots(i)"><i class="fas fa-pencil-alt mr-1"></i> Modifica</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingSlots" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>

<section class="content-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h1>Eccezioni</h1>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button class="btn btn-info mb-3" (click)="addExceptionDate()"><i class="fas fa-plus mr-1"></i>
                    Aggiungi Eccezione</button>

                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table class="table table-striped" datatable [dtOptions]="dtOptionsExceptions" [dtTrigger]="dtTriggerExceptions">
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Orari</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let e of exceptions">
                                    <td>{{getDate(e.date)}}</td>
                                    <td>{{getExceptionSlots(e.slots)}}</td>

                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="editExceptionslots(e)"><i class="fas fa-pencil-alt mr-1"></i> Modifica</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteExceptionSwal"><i class="fas fa-trash"></i> </button>

                                        <swal #deleteExceptionSwal title="Elimina" text="Vuoi eliminare questa eccezione ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" (confirm)="deleteException(e)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingExceptions" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>

<section class="content-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h1>Appuntamenti</h1>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <div class="card card-info card-outline">
                    <div class="card-body table-responsive">
                        <table class="table table-striped" datatable [dtOptions]="dtOptionsBookings" [dtTrigger]="dtTriggerBookings">
                            <thead>
                                <tr>
                                    <th>Data e Ora</th>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Telefono</th>
                                    <th>Note</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let b of bookings">
                                    <td>{{b.date * 1000 | date : 'EEE dd/MM'}} | {{getSlot(b.start_time)}} - {{getSlot(b.end_time)}}</td>
                                    <td>{{b.fullname}}</td>
                                    <td>{{b.email}}</td>
                                    <td>{{b.phone}}</td>
                                    <td>{{b.notes}}</td>

                                    <td class="text-right">
                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i class="fas fa-trash"></i> </button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo appuntamento ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" (confirm)="deleteBooking(b)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingBookings" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>