<form [formGroup]="form">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-12">
                <label for="date">Giorno</label>
                <input [owlDateTime]="dt_date" [owlDateTimeTrigger]="dt_date" type="text" class="form-control" id="date" formControlName="date" readonly="readonly"
                    [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
                <owl-date-time [pickerType]="'calendar'" #dt_date></owl-date-time>
                <div *ngIf="f.date.errors?.required" class="invalid-feedback">Campo
                    obbligatorio</div>
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button type="submit" class="btn btn-info" (click)="save()">Salva</button>
    </div>
</form>