import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgendaException, AgendaSlot } from 'src/app/models/agenda';
import { RestService } from 'src/app/utils/services/rest.service';
import { ModalAgendaSlotAddComponent } from '../modal-agenda-slot-add/modal-agenda-slot-add.component';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-agenda-exception-slots',
  templateUrl: './modal-agenda-exception-slots.component.html',
  styleUrls: ['./modal-agenda-exception-slots.component.css']
})
export class ModalAgendaExceptionSlotsComponent implements OnInit {

  @Input() item: AgendaException;

  constructor(public activeModal: NgbActiveModal, private rest: RestService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  getDate(item) {
    moment.locale("it");
    return moment(item, "YYYY-MM-DD").format("dddd DD-MM-YYYY");
  }

  getSlot(item) {
    return moment(item, "HH:mm:ss").format("HH:mm");
  }

  /**
   * Aggiunta di uno slot
   */
  addSlot() {
    const modalRef = this.modalService.open(ModalAgendaSlotAddComponent);
    modalRef.result.then((result: AgendaSlot) => {
      this.rest.agendaExceptionSlotAdd(this.item.date, result.start_time, result.end_time).subscribe(res => {
        this.item.slots.push(res.data.slot);
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }, (reason) => { });
  }

  /**
   * Eliminazione di uno slot
   */
  deleteSlot(item: AgendaSlot) {
    this.rest.agendaExceptionSlotDelete(item.slot_id).subscribe(res => {
      this.item.slots = this.item.slots.filter(i => i.slot_id != item.slot_id);
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Imposta come giorno di chiusura, senza slots
   */
  dateClose() {
    this.rest.agendaExceptionDateClose(this.item.date).subscribe(res => {
      this.item.slots = [];
      this.dismiss();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
