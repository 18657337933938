import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Document } from 'src/app/models/document';
import { RestService } from 'src/app/utils/services/rest.service';
import bsCustomFileInput from 'bs-custom-file-input';
declare var $: any;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isLoading: boolean;
  submitted = false;
  saving = false;

  form: FormGroup;

  office_id: number;
  user_id: number;
  category_id: number;

  documents: Document[];
  isUpdate: boolean;
  fileAttachment: File;
  isLoadingDocuments: boolean;
  submittedDocument = false;
  sendingDocument = false;

  constructor(private router: Router, private route: ActivatedRoute, private rest: RestService, private toastr: ToastrService, private formBuilder: FormBuilder) {
    this.office_id = this.route.snapshot.params['id'];
    this.user_id = this.route.snapshot.params['user_id'];
    this.category_id = this.route.snapshot.params['category_id'];
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [
        { targets: 0, type: 'date-euro' },
        { orderable: false, targets: [4] }],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = this.formBuilder.group({
      document_id: [null],
      title: ['', Validators.required],
      description: [''],
      owner_name: [''],
      attachment: [null]
    });


    this.documentsList();
  }

  ngAfterViewInit() {
    bsCustomFileInput.init();
  }

  get f() {
    return this.form.controls;
  }

  /**
* Lista 
*/
  documentsList() {
    this.isLoading = true;

    this.rest.documentsPrivateList(this.category_id).subscribe(res => {
      this.isLoading = false;

      this.documents = res.data.documents;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  handleFile(files: FileList) {
    if (files && files.length > 0) {
      this.fileAttachment = files[0];
    }
  }

  saveDocument() {
    var documentID = this.f.document_id.value;

    this.submittedDocument = true;

    if (!documentID && !this.fileAttachment) {
      this.f.attachment.setErrors({ 'required': true })
    } else {
      this.f.attachment.setErrors(null)
    }

    if (this.form.invalid) {
      return;
    }

    this.sendingDocument = true;

    const formData = new FormData();
    formData.append("title", this.f.title.value);

    if (this.f.description.value) formData.append("description", this.f.description.value);
    if (this.fileAttachment) formData.append("file", this.fileAttachment);

    if (documentID) {
      formData.append("document_id", documentID);

      this.rest.documentPrivateEdit(formData).subscribe(res => {
        this.sendingDocument = false;

        $("#modal").modal('hide');
        this.toastr.success('Documento modificato con successo!');
        this.documentsList();
      }, error => {
        this.sendingDocument = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      formData.append("category_id", this.category_id.toString());
      formData.append("user_id", this.user_id.toString());

      this.rest.documentPrivateAdd(formData).subscribe(res => {
        this.sendingDocument = false;

        $("#modal").modal('hide');
        this.toastr.success('Documento aggiunto con successo!');
        this.documentsList();
      }, error => {
        this.sendingDocument = false;

        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }


  editDocument(item: Document) {
    this.submittedDocument = false;

    $('#form')[0].reset();

    if (item) {
      this.form.patchValue({
        document_id: item.document_id,
        title: item.title,
        description: item.description
      });
    }

    $("#modal").modal('show');
  }

  deleteDocument(item: Document) {
    this.rest.documentPrivateDelete(item.document_id).subscribe(res => {
      this.toastr.success('Documento eliminato con successo!');
      this.documentsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    // bsCustomFileInput.destroy();
  }
}
