import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { User, UserCategory } from 'src/app/models/user';
import bsCustomFileInput from 'bs-custom-file-input';
import { DocumentCategory } from 'src/app/models/document-category';

declare var $: any;

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isLoading: boolean;
  submitted = false;
  saving = false;

  form: FormGroup;
  form_category: FormGroup;

  office_id: number;
  user_id: number;

  user: User;
  managers: User[];

  sendingCredentials = false;
  submittedCredentials = false;

  categories: DocumentCategory[];
  userCategories: UserCategory[];
  isUpdate: boolean;
  isLoadingCategories: boolean;
  submittedCategory = false;
  sendingCategory = false;

  constructor(private router: Router, private route: ActivatedRoute, private rest: RestService, private toastr: ToastrService, private formBuilder: FormBuilder) {
    this.office_id = this.route.snapshot.params['id'];
    this.user_id = this.route.snapshot.params['user_id'];
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [
        { targets: 0, type: 'date-euro' },
        { orderable: false, targets: [2] }],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = this.formBuilder.group({
      code: [null, Validators.required],
      password: [null],
      fullname: [null],
      email: [null],
      phone: [null],
      categories: [null]
    });

    this.form_category = this.formBuilder.group({
      category_id: [null],
      name: ['', Validators.required],
    });

    this.userCategoriesList();

    if (this.user_id) {
      this.userDetail();
      this.categoriesDocumentsList();
    }
  }

  ngAfterViewInit() {
    bsCustomFileInput.init();
  }

  get f() {
    return this.form.controls;
  }

  get f_category() {
    return this.form_category.controls;
  }

  userDetail() {
    this.isLoading = true;

    this.rest.userDetail(this.user_id).subscribe(res => {
      this.isLoading = false;

      this.user = res.data.user;
      this.managers = res.data.managers;

      this.form.patchValue({
        code: this.user.code,
        fullname: this.user.fullname,
        email: this.user.email,
        phone: this.user.phone,
        categories: this.user.categories.map(c => c.category_id)
      })
    });
  }

  /**
   * Lista categorie clienti
   */
  userCategoriesList() {
    this.rest.categoriesUsersList().subscribe(res => {
      this.userCategories = res.data.categories;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
  * Lista categorie documenti
  */
  categoriesDocumentsList() {
    this.isLoading = true;

    this.rest.categoriesDocumentsPrivateList(this.user_id).subscribe(res => {
      this.isLoading = false;

      this.categories = res.data.categories;

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  // Salvataggio del profilo utente
  save() {
    this.submitted = true;

    if (this.user_id && this.f.password) {
      this.f.password.setValidators([Validators.minLength(8), Validators.maxLength(45)]);
      this.f.password.updateValueAndValidity();
    } else {
      this.f.password.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(45)]);
      this.f.password.updateValueAndValidity();
    }

    if (this.form.invalid) {
      return;
    }

    var bodyData = {
      code: this.f.code.value,
      fullname: this.f.fullname.value,
      email: this.f.email.value,
      phone: this.f.phone.value,
      categories: this.f.categories.value
    };

    if (this.f.password.value) {
      bodyData['password'] = this.f.password.value;
    }

    this.saving = true;

    if (this.user_id) {
      bodyData['user_id'] = this.user_id;

      this.rest.userEdit(bodyData).subscribe(res => {
        this.saving = false;
        this.toastr.success('Profilo aggiornato con successo!');
      }, error => {
        this.saving = false;
        if (error == 3) {
          this.toastr.warning('Codice utente già esistente!');
        } else {
          this.toastr.error('Si è verificato un errore, riprova!');
        }
      });
    } else {
      bodyData['office_id'] = this.office_id;

      this.rest.userAdd(bodyData).subscribe(res => {
        this.saving = false;
        this.toastr.success('Profilo creato con successo!');
        this.router.navigate([`/office/${this.office_id}/users`]);
      }, error => {
        this.saving = false;
        if (error == 3) {
          this.toastr.warning('Codice utente già esistente!');
        } else {
          this.toastr.error('Si è verificato un errore, riprova!');
        }
      });
    }
  }

  // invio delle credenziali alla mail dell'utente
  sendCredentials() {
    this.submittedCredentials = true;

    var password = this.f.password.value;
    var email = this.f.email.value;

    if (!password) {
      this.f.password.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(45)]);
      this.f.password.updateValueAndValidity();
    }
    if (!email) {
      this.f.email.setValidators([Validators.required, Validators.email]);
      this.f.email.updateValueAndValidity();
    }

    if (this.form.invalid) {
      return;
    }

    this.sendingCredentials = true;

    this.rest.userChangePassword(this.user_id, password, email).subscribe(res => {
      this.sendingCredentials = false;
      this.submittedCredentials = false;
      this.toastr.success('Password modificata con successo e credenziali inviate all\'utente!');
    }, error => {
      this.sendingCredentials = false;
      this.submittedCredentials = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });

  }

  //***************** CATEGORIE DOCUMENTI */

  saveCategoryDocument() {
    var categoryID = this.f_category.category_id.value;

    this.submittedCategory = true;

    if (this.form_category.invalid) {
      return;
    }

    this.sendingCategory = true;
    var name = this.f_category.name.value;

    if (categoryID) {
      this.rest.categoriesDocumentsPrivateEdit(categoryID, name).subscribe(res => {
        this.sendingCategory = false;

        $("#modal").modal('hide');
        this.toastr.success('Categoria modificata con successo!');
        this.categoriesDocumentsList();
      }, error => {
        this.sendingCategory = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.categoriesDocumentsPrivateAdd(this.user_id.toString(), name).subscribe(res => {
        this.sendingCategory = false;

        $("#modal").modal('hide');
        this.toastr.success('Categoria aggiunta con successo!');
        this.categoriesDocumentsList();
      }, error => {
        this.sendingCategory = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

  editCategoryDocument(item: DocumentCategory) {
    this.submittedCategory = false;

    $('#form_category')[0].reset();

    if (item) {
      this.form_category.patchValue({
        category_id: item.category_id,
        name: item.name
      });
    }

    $("#modal").modal('show');
  }

  deleteCategoryDocument(item: DocumentCategory) {
    this.rest.categoriesDocumentsPrivateDelete(item.category_id).subscribe(res => {
      this.toastr.success('Categoria eliminata con successo!');
      this.categoriesDocumentsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  openCategoryDocument(item: DocumentCategory) {
    this.router.navigate([`/office/${this.office_id}/users/${this.user_id}/documents/${item.category_id}`]);
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    // bsCustomFileInput.destroy();
  }
}
