import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { AppConstants } from '../constants';

@Injectable()
export class GlobalEventsManager {
  private _showOfficeNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showOfficeNavBarEmitter: Observable<boolean> = this._showOfficeNavBar.asObservable();

  private _setOfficeName: BehaviorSubject<String> = new BehaviorSubject<String>('');
  public getOfficeNameEmitter: Observable<String> = this._setOfficeName.asObservable();

  constructor(private auth: AuthenticationService) {
    this._setOfficeName.next(localStorage.getItem('_ms_office-name'));
    this.auth.isLogout.subscribe(isLogout => { if (isLogout) this.removeOfficeName(); });
  }

  setOfficeName(name: string) {
    this._setOfficeName.next(name);
    localStorage.setItem('_ms_office-name', name);
  }

  removeOfficeName() {
    this._setOfficeName.next('');
    localStorage.removeItem('_ms_office-name');
  }

  showOfficeNavBar(ifShow: boolean) {
    // se non mi trovi all'interno di un ufficio, non mostro il nome
    if (!ifShow) {
      this.removeOfficeName();
    }

    this._showOfficeNavBar.next(ifShow);
  }

  // ufficio
  isOffice() {
    return this.auth.currentUserValue.type_id == AppConstants.USER_TYPE_OFFICE;
  }

  // super admin thnet
  isUserSuperAdmin() {
    return this.auth.currentUserValue.type_id == AppConstants.USER_TYPE_SUPER_ADMIN;
  }

  // manager
  isManager() {
    return this.auth.currentUserValue.type_id == AppConstants.USER_TYPE_MANAGER;
  }

  getUserID() {
    return this.auth.currentUserValue.user_id;
  }

  getUserRole() {
    return this.auth.currentUserValue.type_id;
  }

  getUserTypeLogged() {
    var roleId = this.auth.currentUserValue.type_id;
    return AppConstants.USER_TYPES.filter(function (u) {
      return roleId === u.type_id;
    }).map(function (u) {
      return u.type_name;
    })
  }

  getUserType(type_id) {
    return AppConstants.USER_TYPES.filter(function (u) {
      return type_id === u.type_id;
    }).map(function (u) {
      return u.type_name;
    })
  }

  printHtml(html) {
    var newWin = window.open('', 'Print-Window');
    newWin.document.open();
    newWin.document.write('<html><body onload="window.print()">' + html + '</body></html>');
    newWin.document.close();
    setTimeout(function () {
      newWin.close();
    }, 10);
  }

  printHtmlWithCSS(style, title, html) {
    var newWin = window.open('', 'Print-Window');
    newWin.document.open();
    newWin.document.write('<html><head><style type="text/css">' + style + '</style><title>' + title + '</title></head><body onload="window.print()">' + html + '</body></html>');
    newWin.document.close();
    setTimeout(function () {
      newWin.close();
    }, 10);
  }

  checkPassword(value) {
    if (value != AppConstants.PASSWORD_DELETE) return "Password errata";
  }
}
