import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SigninResponse } from 'src/app/models/response/signin-response';
import { Admin } from 'src/app/models/admin';
import { Base } from 'src/app/models/base';
import { Office } from 'src/app/models/office';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Admin>;
  public currentUser: Observable<Admin>;

  @Output() isLogout: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Admin>(JSON.parse(localStorage.getItem('_ms_user')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Admin {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<SigninResponse>(`${environment.APIweb}/auth/signin`, { email, password })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response        
        if (response && response.data && response.data.user && response.data.auth.token) {
          localStorage.setItem('_ms_user', JSON.stringify(response.data.user));
          localStorage.setItem('_ms_token', response.data.auth.token);
          this.currentUserSubject.next(response.data.user);
        }

        return response;
      }));
  }

  signup(email: string, password: string, name: string, phone: string, coupon: string) {
    return this.http.post<Base>(`${environment.APIweb}/auth/signup`, { email, password, name, phone, coupon });
  }

  forgotPassword(email: string) {
    return this.http.post<Base>(`${environment.APIweb}/auth/password/forgot`, { email });
  }

  changePassword(password: string, token: string) {
    return this.http.post<Base>(`${environment.APIweb}/auth/password/change`, { password, token });
  }

  logout() {
    localStorage.removeItem('_ms_user');
    localStorage.removeItem('_ms_token');
    localStorage.removeItem('_ms_office');

    this.currentUserSubject.next(null);
    this.isLogout.emit(true);
  }

  getToken(): string {
    return localStorage.getItem('_ms_token');
  }

  isLogged() {
    return this.getToken() != null;
  }

  getOffice(): Office {
    return JSON.parse(localStorage.getItem('_ms_office'));
  }

  saveOffice(office: Office) {
    localStorage.setItem('_ms_office', JSON.stringify(office));
  }

  // office premium
  isPremium(): boolean {
    var office = this.getOffice();
    return office && office.premium == 1 && (moment().unix() - office.premium_expiration_date < 86400);
  }
}
